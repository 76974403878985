const en = {
//main
langEN : `en`,
langFR : `fr`,
discoverBtn: `Discover`,
tryDemoBtn: `Try demo`,
tryTodayBtn: `Try today`,
bookBtn : `Book a meeting`,
tryThisBtn: `Try this`,
aboutThisBtn: `Enquire about this`,
getStartedBtn: `Get started!`,
getStartedCustomerBtn: `Get started!`,

//modal
modalTitle: `get an offer`,
modalDesc: `You are one step away from getting an offer that boosts your customer experience to the next level. Give us an overview of your challenges and we will get back to you asap!`,
modalTitle2: `Thank you!`,
modalDesc2: `Your application has been sent. We will contact you soon.`,
modalDesc3: `If you are ready to go straight to communication, you can make an appointment with our wired specialist at a convenient time for you.`,
modalBtn1: `Make appointment`,
modalBtn2: `Return to site`,
modalDemoTitle: `request a demo`,
modalDemoDesc: `Thank you for taking the first step in improving the experience of your customers. Our team will schedule a meeting with you so you can test our tools as soon as possible. Have a great day!`,
modalFirstName: `First name *`,
modalLastName: `Last name *`,
modalPhone: `Phone number *`,
modalRequest: `Your ask or request`,
modalSend: `Send`,
//newslatter
newslatterTitle: `Keep in touch with our newsletter`,
newslatterDesc: `Join the Evo Home community so you can be up to date with the latest innovation and release of the company and more.`,
newslatterSendTitle: `Subscribe to our newsletter to receive advice and information about Real Estate.`,
newslatterSendTitleThx: `Thank you for subscribing to our newsletter!`,
newslatterSubscribeBtn: `Subscribe`,
//header
headerProducts: `Products`,
headerServices: `Services`,
headerNews: `News`,
headerAboutUs: `About Us`,
headerCRM: `CRM`,
headerVisualizer: `Visualizer`,
headerConfigurator: `Configurator`,
headerReviewer: `Reviewer`,
headerCustomerManagmentPlatform: `Customer management platform`,
headerSalesManagement: `Sales Management`,
headerDigitalMarketing: `Digital Marketing`,
headerOffer: `get an offer`,
headerSalesManagementSmall: `sales Management`,
headerBlog: `Blog`,
headerPodkasts: `Podcasts`,
//footer
footerCory: `All rights reserved.`,
footerPrivacy: 'Privacy Policy',
footerAddress: 'Address',
//mainpage
mainDescp1: `Digital tools to simplify the `,
mainDescp2: `journey `,
mainDescp3: `of real estate customers`,
mainCustomersTitle: `Discover why many trusted real estate players trust our solutions`,
mainIconsTalked: `They talked about us`,
mainProductsTitle: `Evo home's platform is a magic toolkit that powers up your real estate customer management.`,
mainProductsDesc: `Each tool tackles a specific tension in your customer's journey while making your operations more efficient.`,
mainCRMDesc: `Improve your sales management by collecting, managing and analysing your customer and lead data`,
mainVisualizerDesc: `Share anywhere a powerful visualisation of any architecture plan in quality 3D`,
mainConfiguratorDesc: `Helps your customer customise and vizualize his future property in 3D. Streamline your customer's decision making with clear options`,
mainReviewerDesc: `Streamline your customers feedback loops and approval`,
mainCustomerManagmentPlatformDesc: `Helps your customer customise and vizualize his future property in 3D. Streamline your customer's decision making with clear options`,
mainSalesManagementDesc: `Boost your sales by outsourcing a professional brokerage team for a fraction of the costs`,
mainDigitalMarketingDesc: `Helps you generate leads and awareness online on your project efficiently`,

mainCustomerTitle: `Improve your customer's journey and customer service`,
mainCustomerDesc: `Your customer has a one stop shop for all his information regarding your project, can visualize easily your 3D plans and interact with your customer service teams`,
mainCustomerTitle2: `Better organization of data`,
mainCustomerDesc2: `All your customer's information and decisions are organized so you can efficiently act on it`,
mainCustomerTitle3: `All your customer's information in one place`,
mainCustomerDesc3: `Gathering feedbacks from various sources? Unsure where a client information is? Evohom has it all organized in one place.`,
mainCustomerTitle4: `Automate non added value tasks`,
mainCustomerDesc4: `Need to send a email reminder, or onboarding a client's with a compelling communication? Need to notify your client's of a change? Evohom can automate all that`,

mainReviewsTitle: `Hear it from our clients`,
// mainReviewsDesc: `Our clients trust our solutions to help their customers and boost their operations. Check below what they have to say about our service:`,
// mainReviwerDesc1: `Evohome est une solution qui focalise sur ce qui est plus important pour le groupe Swissroc : l’expérience client. Les différents modules nous aident à améliorer en continu nos interactions et l’expérience d’acquisition.`,
// mainReviwerName1: `Winton Thompson`,
// mainReviwerPosition1: `CEO, Swissroc company`,

mainClientsTitle: `They trust us`,
mainClientsDesc: `These outlets and companies supported us and trust our solutions :`,
mainClientsSubtitle1: `Talked about us`,
mainClientsSubtitle2: `Use evohom`,
//about us
aboutUsTitle: `Our Vision`,
aboutUsSubtitle: `Make real estate simple and exciting`,
aboutUsDesc1: `We want to make real-estate simple and exciting for buyers and developers by creating simple tools that can immerse them in space, educate on key information or simplify transactions.`,
aboutUsDesc2: `We develop powerful digital ecosystems using cutting edge technologies like 3D visualization, service integration and data analytics so buyers and developers can reach and connect with the potential audience for faster conversion.`,
//configurator
configuratorMainTitle: `home configurator`,
configuratorMainDesc: `Helps your customer customise and control your home development process.`,
configuratorMainp1: `The property `,
configuratorMainp2: `conﬁgurator:`,
configuratorMainp3: `When choices become control`,

configuratorDescribe1p1: `Simplify `,
configuratorDescribe1p2: `the decision-making process`,
configuratorDescribe2p1: `Capture `,
configuratorDescribe2p2: `your customer’s choices`,
configuratorDescribe3p1: `Capture `,
configuratorDescribe3p2: `qualified leads`,

configuratorBlocksTitle1: `How it works`,
configuratorBlocksDesc1: `Streamlines your construction briefing process by controlling the decision making and customisation process`,
configuratorBlocksTitle2: `Engage your customer like never before`,
configuratorBlocksDesc2: `Make the home purchase simple and engaging. It works just like a car configurator, your customer can choose layouts, options and finitions in a few click and see the pricing evolving accordingly.`,
configuratorBlocksTitle3: `Streamline decision making`,
configuratorBlocksDesc3: `Design the ideal home purchase journey end 2 end. You can easily design purchase scenarios and make options available so you can better control the scope of decision of your clients.`,
configuratorBlocksTitle4: `Generate more leads`,
configuratorBlocksDesc4: `Integrate Evohome in your website and boost your sales. Evohome converts in average 2x more leads than a normal real estate development website.`,
configuratorBorderSesc: `Your customers would like to know what's happening with the construction of their freshly bought property? The Evo home customer platform facilitates the follow up, coordination and notification of your customers. In one shot they can learn about what's happening with their property : calendar, budget and all that matters.`,

configuratorReviewsTitle: `See it in action`,
configuratorReviewsDesc: `The best approach is to make up your mind by trying it! Click here and let us know if this is something that could help improve your customer experience.`,

configuratorProductsTitle: `You are getting`,
configuratorProductsDesc: `Benefits`,
configuratorProductsTitle1: `Powerful insights on your project`,
configuratorProductsDesc1: `Get insights on what variant works best, which product is most selected, you get to know valuable insights about your customers.`,
configuratorProductsTitle2: `Better control of the budget`,
configuratorProductsDesc2: `Better manage how options are being added and integrated within the projection thanks to proactive selection.`,
configuratorProductsTitle3: `Manage your project`,
configuratorProductsDesc3: `Manage your units status (sold, booked, etc.) in one click and get an overview of the progress of your project.`,

//reviewer
reviewerMainTitle: `evohome Reviewer`,
reviewerMainDesc: `A powerful tool to review and approve architectural decisions.`,

reviewerBlocksTitle1: `How it works`,
reviewerBlocksDesc11: `Make feedback and decision making easier.`,
reviewerBlocksDesc12: `Tired of unorganized feedbacks with unspecific guidelines?`,
reviewerBlocksDesc13: `Here is a tool that can help make it better.`,
reviewerBlocksTitle2: `Comment on 3D architectural files`,
reviewerBlocksDesc2: `Evohom reviewer enables you to comment and share efficiently your thoughts and decisions.`,
reviewerBlocksTitle3: `Tag locations specifically`,
reviewerBlocksDesc3: ` You can tag specifically where something is relevant and add a comment`,
reviewerBlocksTitle4: `Annotate and draw`,
reviewerBlocksDesc4: `Help your team understand your vision with drawing and annotation options.`,

reviewerReviewsTitle: `Check for yourself`,
reviewerReviewsDesc: `The best approach is to make up your mind by trying it! Click here and let us know if this is something that could help improve your customer experience.`,

reviewerProductsTitle: `You are getting`,
reviewerProductsDesc: `Benefits`,
reviewerProductsTitle1: `Bring your feedbacks in one place`,
reviewerProductsDesc1: `Centralize all your feedbacks in one place so you get less emails all over the place!`,
reviewerProductsTitle2: `Get feedback with context`,
reviewerProductsDesc2: `Add geolocalisation of your feedbacks so your team knows exactly where you want it`,
reviewerProductsTitle3: `Approve versions`,
reviewerProductsDesc3: `You can approve several updates of the same model while keeping track of the evolution of the model`,

reviewerDescribep1: `Track `,
reviewerDescribep2: `feedback and communication`,
reviewerDescribe2p1: `Supports `,
reviewerDescribe2p2: `GLB, PDF and JPEG`,
reviewerDescribe3p1: `Make `,
reviewerDescribe3p2: `annotations and upload files`,

//customer
customerMainTitle: `Customer management platform`,
customerMainDesc1: `Alleviate the pain from customer management so you can`,
customerMainDesc2: `focus on what matters. Now integrated with`,

customerBlocksTitle1: `How it works`,
customerBlocksDesc11: `Deliver exellent customer`,
customerBlocksDesc12: `experience thought cloud based`,
customerBlocksDesc13: `data management`,
customerBlocksDesc14: `Your customers would like to know what's happening with the construction of their newly bought property? The Evo home Customer Management Platform facilitates the follow up, coordination and notification of your customers. In one shot they can learn about what's happening with their property: construction calendar, budget and all that matters, while automatizing the exchange of documentation like quotes and contracts with digital signature.`,
customerBlocksTitle2p1: `Automatize`,
customerBlocksTitle2p2: ` the document exchange`,
customerBlocksDesc2p1: `Share quotes, invoices and documents with your clients. You can automatize the signature process to match your organization’s own processes.`,
customerBlocksDesc2p2: `Get notifications every time a customer signs a document and keep track of all previous shared documentation in one place.`,
customerBlocksSecureText: `Our integration with DocuSign means that your files can be signed in a secured way`,
customerDescribeTitlep1: `Keep your customers `,
customerDescribeTitlep2: `informed`,
customerDescribeDescp1: `Customize the development stages of each unit or building and give your customers clarity on the current status of their property.`,
customerDescribeDescp2: `Share important updates on their calendar, like construction events, meetings or any alterations to their planning, so they get automatic notifications directly to their emails.`,
customerBlocksTitle4: `Watch this video to understand more in deph what the portal can do for you:`,

customerProductsTitle: `You are getting`,
customerProductsTitle1: `File Management`,
customerProductsDesc1: `Organize your clients in sub group or batches for better tracking.`, // wrong text in BenefitVisualizerSection
customerProductsDesc11: `Manage and overview all important documents related to your clients and track their status.`,
customerProductsTitle2: `Feed Posts`,
customerProductsDesc2: `Make posts to your clients feed just like in social media and reach them all at once.`,
customerProductsTitle3: `Notifications`,
customerProductsDesc3: `Automatised emails that remind your clients to sign or review documents.`,

customerContactMeTitle: `Try our integration with`,
customerContactMeTitle2: `Avid Procore user?`,
customerContactMeDesc2: `Now you can integrate Evo Home with your own Procore account to get the best of both tools!`,
customerContactMeTitle3p1: `Get in touch with us for a`,
customerContactMeTitle3p2: `tailored solution`,
customerContactMeTitle3p3: `Get in touch with us for a`,
customerContactMeTitle3p4: `tailored solution`,
customerContactMeTitleThx: `Thank you! We will contact you asap`,
customerContactMeButton: `Contact us`,

customerReviewsTitle: `See it in action`, // used in Reviews1.tsx
customerReviewsDesc: ` The best approach is to make up your mind by trying it! Click here and let us know if this is something that could help improve your customer experience.`, // used in Reviews1.tsx

//visualizer
visualizerMainTitle: `Visualizer`,
visualizerMainDesc: `Visualize, share and present 3D architecture in one click`,

visualizerBlocksTitle1: `how it works`,
visualizerBlocksDesc1p1: `Visualizing architecture 3D objects can be cumbersome, `,
visualizerBlocksDesc1p2: `Evohome visualizer allows you to upload a architectural 3D model and share it with your clients and colleague in a click.`,
visualizerBlocksTitle2p1: `Easy solution to `,
visualizerBlocksTitle2p2: `visualize `,
visualizerBlocksTitle2p3: `a project`,
visualizerBlocksDesc2: `Just upload your 2d file or 3d file and we'll make sure its displayed correctly. You can then share the model in one click and save time on decision making.`,
visualizerBlocksTitle3p1: `Educate `,
visualizerBlocksTitle3p2: `on the key features`,
visualizerBlocksDesc3: `You can add information on the model and tag them to certain points so your audience can get key information on the project, technical or else.`,
visualizerBlocksTitle4p1: `Enable `,
visualizerBlocksTitle4p2: `engagement `,
visualizerBlocksTitle4p3: `with your audience`,
visualizerBlocksDesc4: `You can add lead generation forms or additional content that can be download automatically.`,

visualizerReviewsTitle: `See it in action`,
visualizerReviewsDesc: `The best approach is to make up your mind by trying it! Click here and let us know if this is something that could help improve your customer experience.`,

visualizerProductsTitle: `You are getting`,
visualizerProductsDesc: `Benefits`,
visualizerProductsTitle1: `Discover technical informations in a click`,
visualizerProductsDesc1: `What is the size in m2? What room is described here? Just click on the interactive model to learn more!`,
visualizerProductsTitle2: `Add 2d floorplan for perspective`,
visualizerProductsDesc2: `Whether you prefer 3D for space visualization or prefer 2D for a more technical approach, we have both!`,
visualizerProductsTitle3: `Multiple floor views`,
visualizerProductsDesc3: `You have many floors to present? No problem, a simple menu to travel across each floor with clarity!`,

//crm

crmMainDesc1: `A tool that helps your sales grow!`,
crmMainDesc2: `Improve your sales management by collecting, managing and analysing your customer and lead data`,

crmBlocksTitle1: `how it works`,
crmBlocksDesc11: `A powerful tool to optimize your sales process.`,
crmBlocksDesc12: `Tired of juggling between emails and excel sheets to track your customers?`,
crmBlocksDesc13: `Get all the clients information organized in one shot, track them and get powerful insight so you can close your sales faster.`,
crmBlocksTitle2p1: `All your`,
crmBlocksTitle2p2: `client's`,
crmBlocksTitle2p3: `information in one shot`,
crmBlocksDesc2: `All the key information of your client organized and prioritized so you can focus on closing sales.`,
crmBlocksTitle3: `Clear tracking of their latest actions`,
crmBlocksDesc3: `Tailor your sales approach by checking the latest actions taken from your clients. Throuch which channel they reached you, their latest contact, what is the product that interests them.`,
crmBlocksTitle4p1: `Automate`,
crmBlocksTitle4p2: `your follow up`,
crmBlocksDesc4: `With Evohom you can automate your client's email chains so you can inform, update or onboard your customers with relevant information whenever they register.`,
crmDescribeTitlep1: `Add information and track your `,
crmDescribeTitlep2: `next steps`,

crmProductsTitle: `You are getting`,
crmProductsDesc: `Benefits`,
crmProductsTitle1: `Tag and organize`,
crmProductsDesc1: `Organize your clients in sub group or batches for better tracking.`,
crmProductsTitle2: `Task management`,
crmProductsDesc2: `A personalized to do list for each client so you can follow up efficiently.`,
crmProductsTitle3: `Powerful analytics for powerful insights on your sales`,
crmProductsDesc3: `In one click you can get powerful insights on what works and what needs improvement thanks to our analytics.`,


//sales
salesMainDesc1: `Struggling with selling your properties?`,
salesMainDesc2: `Trust a Evohome agent to handle your sales end 2 end.`,

salesProductsTitle: `Evo home agents help you close sales faster`,
salesProductsDesc: `Whether you are a real estate developer, a construction company or a borkerage firm in need of support, Evohome can help you manage your sales to clients from a to z.`,
salesProductsTitle1: `Field presence where and when you need it`,
salesProductsDesc1: `We are a digital company but we believe in human contact. Thanks to our network of independant agents across Switzerland, we can quickly mobilise an Evohome agent wherever your customers are.`,
salesProductsTitle2: `Activate your digital super powers`,
salesProductsDesc2: `Benefit from our database of prospects and our Evohome tools so your customer is taken care of from the first email to the notary signature and beyond.`,
salesProductsTitle3: `Full marketing support in house`,
salesProductsDesc3: `Our marketing team expertise in content creation, architecture, 3D visualization, digital and offline will put your project on the forefront and ensure powerful awareness and engagement.`,
salesProductsTitle4: `No additional fees`,
salesProductsDesc4: `One price, no additional fees or invoices. With Evohome you get a competitive offer with a percentage of sales and we take care of everything with no additional spending or outsourcing required.`,

salesRightTitlep1: `Our `,
salesRightTitlep2: `agent `,
salesRightTitlep3: `is always in touch`,
salesRightDesc: `Thanks to Evo home digital approach, our agents are always available to help your customers, whether its by mobile, email or video call. We complete your team and help you close deals!`,

salesAProductsTitle: `You are getting`,
salesAProductsDesc: `Benefits`,
salesAProductsTitle1: `White label outsourcing`,
salesAProductsDesc1: `We care about helping your brand grow. If you want to put your label on what we do, no problem !`,
salesAProductsTitle2: `All the evo home tools`,
salesAProductsDesc2: `Gain access to all the evo home tools suite`,
salesAProductsTitle3: `Presence on the field`,
salesAProductsDesc3: `Our Evo Home team can assist you wherever your project is in Switzerland`,

//marketing
marketingMainTitle1: `digital`,
marketingMainTitle2: `marketing support`,
marketingMainDesc1: `Digital marketing `,
marketingMainDesc2: `made`,
marketingMainDesc3: ` with real estate expertise so you can generate more leads faster.`,

marketingProductsTitlep1: `Digital `,
marketingProductsTitlep2: `marketing support`,
marketingProductsDesc: `A trusted partner for your digital marketing growth`,
marketingProductsTitle1: `Digital awareness and engagment strategy`,
marketingProductsDesc1: `We turn your business objectives in measurable multi channel strategies to improve your real estate project lead generation`,
marketingProductsTitle2: `Content creation`,
marketingProductsDesc2: `From 3d Visualization, to sales brochure, engaging websites, we got you covered.`,
marketingProductsTitle3: `Lead generation`,
marketingProductsDesc3: `Our Evohome tools help you gather data and benefit from our database of prospects so you can close sales faster.`,

marketingRightTitlep1: `Increase `,
marketingRightTitlep2: `conversion `,
marketingRightTitlep3: `with digital marketing`,
marketingRightDesc: `You want to sell more? You need more leads for that, not any leads, qualified leads that want your products. Good news we know how to bring your conversion rate up and get you the the type of leads that buys with our proven adaptive approach mixing traditional tools, social media presence and impactful content.`,

marketingAProductsTitle: `You are getting`,
marketingAProductsDesc: `Benefits`,
marketingAProductsTitle1: `Powerful analytics reports`,
marketingAProductsDesc1: `Get powerful insight on what to do on your project and campaign with our data studio`,
marketingAProductsTitle2: `Project management support`,
marketingAProductsDesc2: `Our team of Evohome experts help you along the way manage the project and deliver your objectives`,
marketingAProductsTitle3: `Regular reporting`,
marketingAProductsDesc3: `With Evohome, you get on a monthly basis a tailored report on your KPIs and recommendations from our team on how to move forward`,
reviews1p1: `Evo home is a solution that focuses on what is most important for Swissroc : Customer experience.`,
reviews1p2: `All modules help with the continuous improvement of interactions and acquisition experience.`,
reviews2: `Evo home represents an opportunity of digital evolution that boosts sales processes and client experience. It is a high potential solution for anybody interested in improving their future in the Real Estate industry and making it easier.`,
reviews2desc: `Digital Strategies Specialist`,
reviews3autor: `Halter Development`,
reviews3: `We have found a team that listens to us, is flexible and responsive. Evo home understood our needs and offered relevant solutions. Having a partner who offers such a range of services is an asset.`,

aboutusTitlep1: `We’re ready for your next `,
aboutusTitlep2: `challenge!`,
aboutusTeam: `Our Team`,

popupTitle: `We have changed!`,
popupDescp1: `Wild Dots is proud to announce that is now evo∙home`,
popupDescp2: `Same people, same quality, wider service`,
popupBtn: `I want to learn more`,

privacyDesc1: `Thank you for showing interest in partnering with the enterprise. Please be assured that Evo-home holds data protection in the highest regard and understands the risks that come with sharing private information online. Kindly note that in some cases, the entity maybe compelled to seek personal data from the client for the purposes of processing certain service requests however; this information is only acquired upon the consent of the individual.`,
privacyDesc2: `Evo-homeuses all private information including address, email address and telephone in line with the tenets highlighted in General Data Protection Regulation (GDPR) as well as country-specific data protection laws. As a controller, we have implemented several mechanisms within our infrastructure to protect sensitive user information as it is processed through our system. Nonetheless, it is not possible to guarantee absolute protection as certain security gaps may exist.`,
privacyTitle2: `Definitions`,
privacyDesc2p1: `Our data protection declaration is based upon the terms used by European legislator for the implementation of the General Data Protection Regulation (GDPR). To make our policies understandable to the reader, we would like to explain the terminology which has been employed in our data protection declaration.`,
privacyTitle21: `Personal Data`,
privacyDesc21: `Our data protection declaration is based upon the terms used by European legislator for the implementation of the General Data Protection Regulation (GDPR). To make our policies understandable to the reader, we would like to explain the terminology which has been employed in our data protection declaration.`,
privacyTitle22: `Data Subject`,
privacyDesc22: `A data subject is any identified or identifiable natural person who personal information is utilized or processed by a controller.`,
privacyTitle23: `Processing`,
privacyDesc23: `Processing is a specific operation or collection of operations which is performed on personal data. These operations include but are not limited to recording, structuring, organization, storage, adaptation and other procedures.`,
privacyTitle24: `Restriction of Processing`,
privacyDesc24: `A process of marking stored personal information for limiting its processing.`,
privacyTitle25: `Profiling`,
privacyDesc25: `Profiling relates to the automated processing of personal data for assessing specific elements relating to an identified or identifiable person.`,
privacyTitle26: `Pseudonymisation`,
privacyDesc26: `The process of handling personal data such that it can no longer be associated with a specific data subject without incorporating additional information.`,
privacyTitle27: `Controller or Controller Responsible For the Processing`,
privacyDesc27: `A natural or legal person, public authority, agency or an association that individually or in collaboration with another party determines the objectives of processing personal information.`,
privacyTitle28: `Processor`,
privacyDesc28: `An external party or individual that is responsible for processing personal information on a controller’s behalf.`,
privacyTitle29: `Recipient`,
privacyDesc29: `A natural or legal person or body to which personal information is disclosed whether it is a third party or not.`,
privacyTitle210: `Third party`,
privacyDesc210: `A natural or legal person or external party apart from the data subject who is granted direct authority by a controller to process personal information.`,
privacyTitle211: `Consent`,
privacyDesc211: `Freely given or unmistakable indication provided by a data subject which allows controller to process information related to him or her.`,
privacyAdress1Title: `Name and Address of the Controller`,
privacyAdress2Title: `Name and Address of the Data Protection Officer`,
privacyAdressSubtitle: `Kindly contact our Data Protection Officer directly with all questions and suggestions relating to data protection.`,
privacyCookiesDesc: ` The Internet pages of Evo-home use cookies. Cookies are text files that are stored in a computer system via an Internet browser. Through the use of cookies, Evo-home can provide the users of the platform with more user-friendly services which cannot be made possible without the optimization of cookie settings. The data subject holds the right to permanently deny the setting of cookies and may delete cookies on their Internet Browser at any given time. However, the deactivation of cookie settings may affect the usability of certain functions within our website.`,
privacyDataTitle: `Collection of General Data and Information`,
privacyDataDesc: `The websites of the entity collate a series of general data and information when a data subject or automated system calls up the website. This information is readily stored in the server log files. Please be assured that while utilizing this general data and information, Evo-home does not draw any conclusions about the data subject. However, this information is required to successful achieve website functionality, optimize content, ensure the viability of online systems and assist law enforcement agencies to solve any cyber crimes. Therefore, Evo-home evaluates collated data statistically, with the goal of enhancing the data protection and data security of our enterprise, and to ensure an optimal level of protection for the personal data we process.`,
privacyRegistrationTitle: `Registration on Our Website`,
privacyRegistrationDesc: `The data subject has the possibility to register on the website of the controller with the indication of personal data either by entering it on the sign-up or landing page. Respective input masks determine which information is required to proceed with the registration process. The personal data entered by the data subject is collated and stored exclusively for internal use by the controller or for purposes of the enterprise.`,
privacyRegistrationDesc2: `By voluntarily securing the personal information of a data subject, the controller seeks to offer the data subject contents or services that may only be offered to registered users. Registered persons have the authority to modify personal data specified during the registration at any given time, or to have it completely deleted from the data stock which the controller possesses.`,
privacyRegistrationDesc3: `To enhance safety and security mechanisms, a Data Protection Officer is assigned by the entity to govern the data protection deceleration. Moreover, the entirety of the controller’s employees are available to the data subject in this scenario as contact persons to clarify any concerns, queries or note down any valuable feedback.`,
privacyNewslatterTitle: `Subscription to Our Newsletters`,
privacyNewslatterDesc: `Users are given an opportunity to subscribe to the company’s newsletter once they access the website. The input mask used for this objective is responsible for deciding which elements of personal information are transmitted as well as when the newsletter or email is ordered from the controller to ensure timely dissemination. A key objective of launching this option is to inform the organization’s business partners and clients about enterprise and partner offers which may emerge from time to time.`,
privacyContactTitle: `Contact Possibility via the Website`,
privacyContactDesc: `The website and landing pages owned by Evo-home contain information that enable a quick electronic contact to our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address).`,
privacyPersonalTitle: `Routine Erasure and Blocking of Personal Data`,
privacyPersonalDesc: `The data controller shall process and store the personal data of the data subject only for the period necessary to achieve the purpose of storage, or as far as this is granted by the European legislator or other legislators in laws or regulations to which the controller is subject to.`,
privacyPersonalSubtitle: `The data subject possesses the following rights in accordance with legislative guidelines:`,
privacyPersonalLi1: `Right of confirmation`,
privacyPersonalLi2: `Right of access`,
privacyPersonalLi3: `Right to rectification`,
privacyPersonalLi4: `Right to erasure (Right to be forgotten)`,
privacyPersonalLi5: `Right of restriction of processing`,
privacyPersonalLi6: `Right to data portability`,
privacyPersonalLi7: `Right to object`,
privacyPersonalLi8: `Automated individual decision-making, including profiling`,
privacyPersonalLi9: `Right to withdraw data protection consent`,
privacyProtectionTitle: `Data Protection Provisions About the Application and Use of Facebook`,
privacyProtectionDesc: `Within the Evo-homewebsite, the controller has integrated components of enterprise Facebook which is a globally popular social network. Data subjects are advised to refer to the data protection policy of Facebook which is available at https://facebook.com/about/privacy/, provides information about the collection, processing and use of personal data by Facebook.`,
privacyProtectionTitle2: `Data protection provisions about the application and use of Google Analytics`,
privacyProtectionDesc2: `Within the Evo-home website, the controller has integrated components of Google Analytics which is a web analytics service. Data subjects are advised to review the platform’s policies for information regarding applicable data protection provisions of Google which may be retrieved through https://www.google.com/intl/en/policies/privacy/ and through http://www.google.com/analytics/terms/us.html. The functionality of Google Analytics is also discussed under the following Link https://www.google.com/analytics/.`,
privacyProtectionTitle3: `Data Protection Provisions About the Application and Use of Google Remarketing`,
privacyProtectionDesc3: `Within the Evo-homewebsite, the controller has integrated components of Google Remarketing which is a web analytics service which is a feature of Google AdWords that enables an enterprise to display advertising to Internet users who have previously resided on the enterprise’s Internet site. Data subjects are advised to review the platform’s policies for information regarding applicable data protection provisions of its internet-based advertising services which can be accessed through www.google.de/settings/ads. They can also customize desired settings on each Internet browser which is used by the data subject. Furthermore, the data protections provisions of Google can be access through: https://www.google.com/intl/en/policies/privacy/.`,
privacyProtectionTitle4: `Data Protection Provisions About the Application and Use of Google-Adwords`,
privacyProtectionDesc4: `Within the Evo-home website, the controller has integrated components of Google which is a service for Internet advertising that allows the advertiser to place ads in Google search engine results and across the Google advertising network. The data subject holds the authority of objecting to the interest based advertisement of Google. Therefore, the data subject must access from each of the browsers in use the link www.google.de/settings/ads to personalize their settings. Furthermore, the data protections provisions of Google can be accessed through: https://www.google.com/intl/en/policies/privacy/.`,
privacyProtectionTitle5: `Data Protection Provisions About the Application and Use of LinkedIn`,
privacyProtectionDesc5: `Within the Evo-home website, the controller has integrated components of the LinkedIn Corporation on this website. LinkedIn is a web-based social network that enables users with existing business contacts to connect and to make new business contacts and is also the largest social network of its kind. Data subjects are advised to refer to https://www.linkedin.com/psettings/guest-controls to unsubscribe from e-mail messages, SMS messages and targeted ads and customize their ad settings. LinkedIn also uses affiliates such as Eire, Google Analytics, BlueKai, DoubleClick, Nielsen, Comscore, Eloqua, and Lotame. The setting of such cookies may be denied under https://www.linkedin.com/legal/cookie-policy. Furthermore, the privacy policy of LinkedIn can be access through: https://www.linkedin.com/legal/privacy-policy. Accordingly, the LinkedIn Cookie Policy is available under https://www.linkedin.com/legal/cookie-policy.`,
privacyProtectionTitle6: `Legal Basis for the Processing`,
privacyProtectionDesc6: `Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is party, as is the case, for example, when processing operations are necessary for the supply of goods or to provide any other service, the processing is based on Article 6(1) lit. b GDPR. The same applies to such processing operations which are necessary for carrying out pre-contractual measures, for example in the case of inquiries concerning our products or services. If our company is subject to a legal obligation by which processing of personal data is required, such as for the fulfilment of tax obligations, the processing is based on Art. 6(1) lit. c GDPR. In rare cases, the processing of personal data may be necessary to protect the vital interests of the data subject or of another natural person. This would be the case, for example, if a visitor were injured in our company and his name, age, health insurance data or other vital information would have to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6(1) lit. d GDPR. Finally, processing operations could be based on Article 6(1) lit. f GDPR. This legal basis is used for processing operations which are not covered by any of the abovementioned legal grounds, if processing is necessary for the purposes of the legitimate interests pursued by our company or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data. Such processing operations are particularly permissible because they have been specifically mentioned by the European legislator. He considered that a legitimate interest could be assumed if the data subject is a client of the controller (Recital 47 Sentence 2 GDPR).`,
privacyProtectionTitle7: `Period for which the Personal Data will be Stored`,
privacyProtectionDesc7: `The criteria which is employed to establish the period of storage of personal data is the respective statutory retention period. After expiration of that period, the corresponding datais routinely deleted given the condition that it is no longernecessary for the fulfilment of the contract or the initiation of a contract.`,
privacyProtectionTitle8 : `Provision of Personal Data as Statutory or Contractual Requirement`,
privacyProtectionDesc8: `Evo-home would like to clarify that the provision of personal data is partly required by legal provisions (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner). Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. For example, the data subject is obliged to provide us with personal data when our company signs a contract with him or her.`,
privacyProtectionTitle9: `Existence of automated decision-making`,
privacyProtectionDesc9: `Kindly note that we carry out profiling and/or automated decision-making in adherence to the guidelines that have been set out under legal provisions.`,

// client-manager
clientManagerHome: "Home"
}

export default en