import { RootState } from "./store"

const SET_IS_FIRST_OPEN = "SET_IS_FIRST_OPEN"

export type InitialStateType = {
    isFirstOpen: boolean
}

const initialState: InitialStateType = {
    isFirstOpen: false
}

export const setIsFirstOpen = () => {
   return{
     type: SET_IS_FIRST_OPEN,
   } 
}

type isFirstOpenAC = {
    type: "SET_IS_FIRST_OPEN",
    isFirstOpen: boolean
}

export const getIsFirstOpen = (state: RootState) => state.isFirstOpen.isFirstOpen

export const isFirstOpenReducer = (state = initialState, action: isFirstOpenAC): InitialStateType => {
     switch(action.type){
         case SET_IS_FIRST_OPEN: 
         return {
             ...state,
             isFirstOpen: false
         }
         default: return state
     }
}

