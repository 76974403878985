import React from "react";
import Barnes from '../../assets/images/logos/barnes-projetsneufs-noir.png'
import Capelli from '../../assets/images/logos/capelli-blanc.png'
import Cardis from '../../assets/images/logos/cardis-horizontal.png'
import Ciep from '../../assets/images/logos/ciep.png'
import Ci from '../../assets/images/logos/ci-horizontal.png'
import Gefiswiss from '../../assets/images/logos/gefiswiss.png'
import Glauser from '../../assets/images/logos/glauser.png'
import Halter from '../../assets/images/logos/halter.png'
import Naef from '../../assets/images/logos/naef.png'
import Nhs from '../../assets/images/logos/nhs.png'
import Sh from '../../assets/images/logos/sh.png'
import Prtsa from '../../assets/images/logos/prtsa.png'
import Fgp from '../../assets/images/logos/fgp.png'
import Swissroc from "../../assets/images/logos/swissroc.png";
import Realtime from "../../assets/images/logos/realtime.svg";
import Lm from "../../assets/images/logos/lm.svg";
import Projectim from '../../assets/images/logos/Logo-Projectim_rvb.jpg'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";


const CustomerSection = () => {
  const { t } = useTranslation();
  
  const items = [
    <figure className="customer-logo"><img src={Projectim} alt=""/></figure>,
    <figure className="customer-logo"><img src={Swissroc} alt=""/></figure>,
    <figure className="customer-logo"><img src={Realtime} alt=""/></figure>,
    <figure className="customer-logo"><img src={Lm} alt=""/></figure>,
    <figure className="customer-logo"><img src={Barnes} alt=""/></figure>,
    <figure className="customer-logo"><img src={Capelli} alt=""/></figure>,
    <figure className="customer-logo"><img src={Cardis} alt=""/></figure>,
    <figure className="customer-logo"><img src={Ciep} alt=""/></figure>,
    <figure className="customer-logo"><img src={Ci} alt=""/></figure>,
    <figure className="customer-logo"><img src={Gefiswiss} alt=""/></figure>,
    <figure className="customer-logo"><img src={Glauser} alt=""/></figure>,
    <figure className="customer-logo"><img src={Halter} alt=""/></figure>,
    <figure className="customer-logo"><img src={Naef} alt=""/></figure>,
    <figure className="customer-logo"><img src={Nhs} alt=""/></figure>,
    <figure className="customer-logo"><img src={Sh} alt=""/></figure>,
    <figure className="customer-logo"><img src={Prtsa} alt=""/></figure>,
    <figure className="customer-logo"><img src={Fgp} alt=""/></figure>,
  ];
  const items2 = [
    <figure className="customer-logo"><img src={Sh} alt=""/></figure>,
    <figure className="customer-logo"><img src={Prtsa} alt=""/></figure>,
    <figure className="customer-logo"><img src={Fgp} alt=""/></figure>,
    <figure className="customer-logo"><img src={Swissroc} alt=""/></figure>,
    <figure className="customer-logo"><img src={Projectim} alt=""/></figure>,
    <figure className="customer-logo"><img src={Realtime} alt=""/></figure>,
    <figure className="customer-logo"><img src={Ciep} alt=""/></figure>,
    <figure className="customer-logo"><img src={Naef} alt=""/></figure>,
    <figure className="customer-logo"><img src={Ci} alt=""/></figure>,
    <figure className="customer-logo"><img src={Gefiswiss} alt=""/></figure>,
    <figure className="customer-logo"><img src={Glauser} alt=""/></figure>,
    <figure className="customer-logo"><img src={Halter} alt=""/></figure>,
    <figure className="customer-logo"><img src={Lm} alt=""/></figure>,
    <figure className="customer-logo"><img src={Barnes} alt=""/></figure>,
    <figure className="customer-logo"><img src={Capelli} alt=""/></figure>,
    <figure className="customer-logo"><img src={Cardis} alt=""/></figure>,
    <figure className="customer-logo"><img src={Nhs} alt=""/></figure>,
  ];
  return (
    <section className="section-customer">
      <h3>{t("mainCustomersTitle")}</h3>
      <div className="customer-list">
        <AliceCarousel
          items={items}
          autoPlay
          autoPlayInterval={0}
          infinite
          responsive={{ 0: { items: items.length } }}
          animationDuration={9000}
          disableDotsControls
          disableButtonsControls
          autoHeight
          autoPlayStrategy="none"
          animationEasingFunction="linear"
        />
        <AliceCarousel
          items={items2}
          autoPlay
          autoPlayInterval={0}
          infinite
          responsive={{ 0: { items: items.length } }}
          animationDuration={9000}
          disableDotsControls
          disableButtonsControls
          autoHeight
          autoPlayStrategy="none"
          autoPlayDirection="rtl"
          animationEasingFunction="linear"
          activeIndex={items.length}
        />
      </div>
    </section>
  );
};
export default CustomerSection;
