import React from "react";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  errors?: any;
  className?: string;
  conClassName?: string;
}

const Input = React.forwardRef(
  (
    {
      name,
      label,
      type,
      errors,
      className = "",
      conClassName = "",
      ...rest
    }: IInputProps,
    ref: any
  ) => {
    const error = errors && errors[name] ? errors[name].message : "";

    return (
      <>
        <input
          className={`${className}`}
          type={type}
          name={name}
          ref={ref}
          {...rest}
        />
        {error && <p className="error-text">{error}</p>}
      </>
    );
  }
);

export default Input;
