import React, { useState } from "react";
import ContactModal from "../../utils/ContactModal";
import Pic from '../../assets/images/pic-girl.svg'
import { useTranslation } from "react-i18next";

const ActionMarketingSection = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section className="section-additional wow fadeInUp">
    <ContactModal isOpen={isOpen} setOpen={setIsOpen} isDemo={true} />
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <article className="additional-block">
            <div className="additional-bg">
              <div className="additional-bg_in"></div>
            </div>
            <div className="additional-content">
              <span className="additional-text">{t("tryTodayBtn")}</span>
              <div className="additional-right">
                <a
                  href="https://calendly.com/evo-home"
                  className="btn btn-border"
                  target="_blank"
                >
                  <span>{t("bookBtn")}</span>
                </a>
                <figure className="additional-pic">
                  <img src={Pic} width="221" height="230" />
                </figure>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
  );
};

export default ActionMarketingSection;
