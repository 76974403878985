import React, { useEffect } from "react";
import ProviderScrollToTop from "./context/ScrollToTopContext";
import { isBrowser } from "./utils/isBrowser";
import { createMemoryHistory, createBrowserHistory } from "history";
import { renderRoutes } from "react-router-config";
import routes from "./routes";
import TagManager from "react-gtm-module";
import config from "./config";
import { setLocale } from "./redux/localeReducer";
import { useDispatch } from "react-redux";
import { detect } from 'detect-browser';
import CommonHelmet from "./components/common/CommonHelmet";

const browser = detect();

let history: any = {};
history = !isBrowser ? createMemoryHistory() : createBrowserHistory();

if (config.enableGTM && isBrowser) {
  const GTMArgs = {
    gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
  };
  TagManager.initialize(GTMArgs);
}

function App(props: any) {
  if (props.url) history.replace(props.url);

  const dispatch = useDispatch()

  const Content = () => {
    return <div className="App">
       <CommonHelmet
          keywords={
            'evo home'
          }
          description={
            `Evo Home conçoit des outils digitaux pour simplifier le parcours des clients dans l'immobilier. Découvrez nos prestations de marketing digital et gestion des ventes.`
          }
        />
      {renderRoutes(routes)}
      </div>;
  }

  useEffect(()=>{
    if(!isBrowser) {
      return;
    }
    
    const languages = window.navigator.languages
    if(!!languages.find((e:string) => e.substr(0,2).toLowerCase() === 'fr') && localStorage.i18nextLng === 'fr'){
      dispatch(setLocale('fr'))
    } else {
      dispatch(setLocale(localStorage.i18nextLng))
    }
  },[])

  useEffect(()=>{    
    if(browser && (browser.name === 'safari' || browser.name === 'ios')){
        var body = document.body;
        body.classList.add('safari')
    }
  },[])

  return (
      <ProviderScrollToTop>
        <Content />
      </ProviderScrollToTop>
  );
}

export default App;
