import React, { useRef, useState } from "react";
import IncludeScript from "../../utils/IncludeScript";
import newslatterImage from "../../assets/images/newsletter-pic.svg";
import service from "../../services/service";
import { getCurrentLang } from "../../redux/localeReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "../../utils/Input";
import useValidation from "../../useHooks/useValidation";
import { useForm } from "react-hook-form";
import ReCAPTCHA from 'react-google-recaptcha';

const NewslatterForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isSended, setIsSended] = useState(false);
  const [isShowCaptcha, setIsShowCaptcha] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const currentLang = useSelector(getCurrentLang);
  const validation = useValidation();
  const { register, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const recaptcha = useRef();

  const submit = () => {
    if (email) {
      setFetchingData(true);
      service
        .subscribe(email, currentLang)
        .then(() => setIsSended(true))
        .catch((e) => console.log(e))
        .finally(() => setFetchingData(false));
    }
  };

  const onChangeCaptcha = (value) => {
    if (value) {
        setIsShowCaptcha(false)
        submit();
    }
  }

  const onPressSubmit = () => {
    if (!email) {
        return
    }
    setIsShowCaptcha(true);
  }

  return (
    <section className="section-newsletter wow fadeInUp">
      <IncludeScript name={"initWOW"} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="newsletter-bg">
              <div className="newsletter-bg_in">
                <figure className="newsletter-pic">
                  <img src={newslatterImage} alt="" />
                </figure>
                <div className="newsletter-content">
                  <h3>Newsletter</h3>
                  {t("newslatterSendTitle")}
                  {isSended ? (
                    <div className="newsletter-thankyou">
                      {t("newslatterSendTitleThx")}
                    </div>
                  ) : (
                    <form className="newsletter-form">
                        <div className="newsletter-form_row">
                        {!isShowCaptcha&&
                            <div className="newsletter-form_content">
                              <div className="form-item">
                                <Input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email*"
                                  ref={register(validation.email)}
                                  label={""}
                                  onChange={(e) => setEmail(e.target.value)}
                                  errors={errors}
                                  name={"email"}
                                />
                              </div>
                              <button
                                type="submit"
                                className="btn btn-border"
                                onClick={onPressSubmit}
                                disabled={fetchingData}
                              >
                                <span>{t("newslatterSubscribeBtn")}</span>
                              </button>
                            </div>
                        }
                        {isShowCaptcha&&
                          <div className="form-captcha">
                            <ReCAPTCHA
                                ref={recaptcha}
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={onChangeCaptcha}
                            />
                          </div>
                        }
                        </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewslatterForm;
