import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../initi18n';
import Secure from '../../assets/images/ic-crm-secured.svg';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ContactModal from '../../utils/ContactModal';

const DescribeClientManagerSection = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section
      className="section-describe section-describe-cmp"
      id="container3"
    >
      <ContactModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        isDemo={true}
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="describe-row first describe-row-cmp">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <article className="describe-text describe-text-cmp wow fadeInLeft">
                    <p className="title">
                      <span className="span2">{t('customerBlocksTitle2p1')}</span>
                      <br /> {t('customerBlocksTitle2p2')}
                    </p>
                    <div className="describe-text-cmp-block">{t('customerBlocksDesc2p1')}</div>
                    <div className="describe-text-cmp-block">{t('customerBlocksDesc2p2')}</div>
                    <div className="describe-text-cmp-secured">
                      <span className="secured-icon">
                        <img src={Secure} />
                      </span>
                      <div className="secured-text">{t('customerBlocksSecureText')}</div>
                    </div>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right wow fadeInRight">
                    <figure className="describe-pic describe-cmp-pic">
                      <VideoPlayer
                        src={i18n.language === 'en' ? '/Videos/video8.mp4' : '/Videos/video8fr.mp4'}
                        id={'video8'}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row describe-row-cmp">
              <div className="row flex-row-reverse align-items-center">
                <div className="col-lg-4">
                  <article className="describe-text describe-text-cmp wow fadeInRight">
                    <p className="title">
                      {i18n.language === 'en' ? (
                        <>
                          {t('customerDescribeTitlep1')}
                          <br />
                          <span className="span1">{t('customerDescribeTitlep2')}</span>
                        </>
                      ) : (
                        <>
                          <span className="span1">{t('customerDescribeTitlep2')}</span>
                          {t('customerDescribeTitlep1')}
                        </>
                      )}
                    </p>
                    <div className="describe-text-cmp-block">{t('customerDescribeDescp1')}</div>
                    <div className="describe-text-cmp-block">{t('customerDescribeDescp2')}</div>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-left wow fadeInLeft">
                    <figure className="describe-pic describe-cmp-pic">
                      <VideoPlayer
                        src={i18n.language === 'en' ? '/Videos/video10.mp4' : '/Videos/video10fr.mp4'}
                        id={'video10'}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            {i18n.language === 'en' && (
              <article className="describe-row describe-row-cmp">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-main_content wow fadeInLeft">
                      <article className="section-main_top section-main-cmp-top">
                        <p className="title-video">{t('customerBlocksTitle4')}</p>
                      </article>
                      <figure className="section-main_figure describe-row-cmp-figure describe-pic">
                        <VideoPlayer
                          src={'/Videos/video22.mp4'}
                          id={'video22'}
                          controls={true}
                          playing={false}
                          muted={false}
                          loop={false}
                        />
                      </figure>
                      <p className="section-main_btn section-main-cmp-top-button">
                        <a
                          href="javascript:void(0);"
                          className="btn btn-gradient"
                          onClick={() => setIsOpen(true)}
                        >
                          <span>{t('getStartedBtn')}</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default DescribeClientManagerSection;
