import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logo.svg";
import i18n from "../../initi18n";
import IncludeScript from "../../utils/IncludeScript";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
const MainSection = () => {
  const { t } = useTranslation();
  
  return (
    <main className="section-main" id="container8">
      <IncludeScript name={"initWOW"} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-main_content wow fadeInUp">
              <figure className="main-logo">
                <img src={Logo} alt="" />
              </figure>
              <article className="section-main_text">
                <h2>
                  {t("mainDescp1")}
                  <span>{t("mainDescp2")}</span>
                  {t("mainDescp3")}
                </h2>
              </article>
              <figure className="section-main_figure">
                <VideoPlayer src={i18n.language === 'en' ? "/Videos/video12.mp4": "/Videos/video12fr.mp4"} id={"video12"} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default MainSection;
