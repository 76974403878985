import {RouteConfig} from 'react-router-config';
import AboutUsPage from './pages/AboutUsPage';
import MainPage from './pages/MainPage';
import PrivacyPage from './pages/PrivacyPage';
import ClientManagerPage from './pages/ClientManagerPage'

const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: MainPage,
  },
  {
    path: '/a-propos-de-evo-home',
    exact: true,
    component: AboutUsPage
  },
  {
    path: '/privacy',
    exact: true,
    component: PrivacyPage
  },
  {
    path: '/client-manager',
    exact: true,
    component: ClientManagerPage,
  },
  {
    path: '/:product',
    exact: true,
    component: MainPage,
  },
  
];

export default routes;
