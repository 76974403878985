import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../initi18n";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const DescribeReviwerSection = () => {
  const { t } = useTranslation();

  return (
    <section className="section-describe" id="container9">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="describe-row first">
              <div className="row">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInLeft">
                    <p className="title">
                      <span className="span1">{t("reviewerDescribep1")}</span>{t("reviewerDescribep2")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right wow fadeInRight">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video13.mp4" : "/Videos/video13fr.mp4"} id={"video13"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row flex-row-reverse">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInRight">
                    <p className="title">
                      {t("reviewerDescribe2p1")}<span className="span2">{t("reviewerDescribe2p2")}</span>
                    </p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-left wow fadeInLeft">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video14.mp4" : "/Videos/video14fr.mp4"} id={"video14"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInLeft">
                  <p className="title"><span className="span1">{t("reviewerDescribe3p1")}</span>{t("reviewerDescribe3p2")}</p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right wow fadeInRight">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video15.mp4" : "/Videos/video15fr.mp4"} id={"video15"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DescribeReviwerSection;
