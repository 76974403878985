import React from "react";
import CommonHelmet from "../components/common/CommonHelmet";
import BenefitCustomerSection from "../components/CustomerPlatformPage/BenefitCustomerSection";
import BorderCustomerSection from "../components/CustomerPlatformPage/BorderCustomerSection";
import DescribeCustomerSection from "../components/CustomerPlatformPage/DescribeCustomerSection";
import MainCustomerPlatformSection from "../components/CustomerPlatformPage/MainCustomerPlatformSection";
import ContactMeCustomerSection from '../components/CustomerPlatformPage/ContactMeCustomerSection'
import Footer from "../components/MainComponents/Footer";
import Header from "../components/MainComponents/Header";
import NewslatterForm from "../components/MainComponents/NewslatterForm";

const CustomerPlatformPage = () => {
  return (
    <>
      <CommonHelmet
          keywords={
            'gestion clients immobilier'
          }
          description={
            `La plateforme de gestions de clients permet un suivi de chantier optimal. Budget, calendrier de visites et travaux, notifications`
          }
        />
      <Header />
      <MainCustomerPlatformSection />
      <BorderCustomerSection />
      <DescribeCustomerSection />
      <BenefitCustomerSection />
      <ContactMeCustomerSection/>
      <NewslatterForm />
      <Footer />
    </>
  );
};

export default CustomerPlatformPage;
