import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactModal from "../../utils/ContactModal";

const ActionCRMSection = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section className="section-action wow fadeInUp">
      <ContactModal isOpen={isOpen} setOpen={setIsOpen} isDemo={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <article className="action-block">
              <div className="additional-bg">
                <div className="additional-bg_in"></div>
              </div>
              <div className="action-content">
                <h3>{t("configuratorReviewsTitle")}</h3>
                <div className="action-text">
                  <div className="action-text_left">
                    {t("configuratorReviewsDesc")}
                  </div>
                  <div className="action-right">
                    <a
                      href="javascript:void(0);"
                      className="btn btn-border"
                      onClick={() => setIsOpen(true)}
                    >
                      <span>{t("tryDemoBtn")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ActionCRMSection;
