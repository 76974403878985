import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
// import OfferImg from "../assets/images/get-offer.svg";
import service from '../services/service';
import useValidation from '../useHooks/useValidation';
import Input from './Input';
import ThankYouModal from './ThankYouModal';
import Logo from '../assets/images/logo.svg';
import { useSelector } from 'react-redux';
import { getCurrentLang } from '../redux/localeReducer';
import { Link, useHistory } from 'react-router-dom';

interface IContactModalProps {
  isOpen: boolean;
  setOpen: any;
  isDemo: boolean;
}

const ContactModal = ({ isOpen, setOpen, isDemo }: IContactModalProps) => {
  const { handleSubmit, register, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation();
  const history = useHistory();
  const path = history.location.pathname;
  const [isOpenThankYouModal, setOpenThankYouModal] = useState(false);
  const validation = useValidation();
  const [buttonStatus, setButtonStatus] = useState(false);
  const currentLang = useSelector(getCurrentLang);

  const onSubmit = (data: any) => {
    data.language = currentLang;
    setButtonStatus(true);
    if (isDemo) {
      service.tryDemoForm(data);
    } else {
      service
        .contactForm(data)
        .then(() => {
          (window as any).dataLayer.push({
            event: 'generate_lead',
            eventCategory: 'Form',
            eventLabel: 'demo',
          });
        })
        .catch((e) => console.log('ERROR'));
    }
    setButtonStatus(false);
    setOpen(false);
    setOpenThankYouModal(true);
  };

  return (
    <div className="modal fade" id="getOffer">
      <ThankYouModal
        isOpenThankYouModal={isOpenThankYouModal}
        setOpenThankYouModal={setOpenThankYouModal}
      />
      <Modal
        preventScroll={true}
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        className="Modal"
        overlayClassName="Overlay">
        <div className="modal-dialog modal-getoffer">
          <div className="modal-content">
            <Link
              to={path === '/client-manager' ? 'client-manager' : '/'}
              data-dismiss="modal"
              className="modal-logo">
              <img src={Logo} alt="Evo home (logo)" />
            </Link>
            <button
              type="button"
              className="modal-close"
              data-dismiss="modal"
              style={{ zIndex: 100 }}
              onClick={() => setOpen(false)}></button>
            <div className="modal-body get-offer">
              {isDemo ? (
                <>
                  <h2>{t('modalDemoTitle')}</h2>
                  <div className="text">{t('modalDemoDesc')}</div>
                </>
              ) : (
                <>
                  <h2>{t('modalTitle')}</h2>
                  <div className="text">{t('modalDesc')}</div>
                </>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-item">
                      <Input
                        type="text"
                        name={'firstName'}
                        errors={errors}
                        label={''}
                        className="form-control"
                        placeholder={t('modalFirstName')}
                        ref={register(validation.required)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-item">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder={t('modalLastName')}
                        name={'lastName'}
                        ref={register(validation.required)}
                        label={''}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-item">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder={t('modalPhone')}
                        ref={register(validation.phone)}
                        label={''}
                        errors={errors}
                        name={'phone'}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-item">
                      <Input
                        type="email"
                        className="form-control"
                        placeholder="Email *"
                        ref={register(validation.email)}
                        label={''}
                        errors={errors}
                        name={'email'}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-item">
                      <textarea
                        placeholder={t('modalRequest')}
                        className="form-control"
                        ref={register}
                        name={'message'}></textarea>
                    </div>
                  </div>
                </div>
                {buttonStatus ? (
                  <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={50}
                    width={100}
                  />
                ) : (
                  <p className="text-right">
                    <button type="submit" className="btn btn-border">
                      <span>{t('modalSend')}</span>
                    </button>
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ContactModal;
