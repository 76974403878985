import React from 'react';
import FileManagement from '../../assets/images/ic-crm-file-management.svg';
import FeedPosts from '../../assets/images/ic-crm-feed-posts.svg';
import Notifications from '../../assets/images/ic-crm-notifications.svg';
import { useTranslation } from 'react-i18next';

const BenefitCustomerSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-benefit section-benefit-cmp">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="wow fadeIn">{t('customerProductsTitle')}</h3>
            <div className="benefit-block-cmp">
              <div className="row">
                <div className="col-md-4">
                  <article className="benefit-one">
                    <span className="benefit-icon wow fadeIn">
                      <img src={FileManagement} />
                    </span>
                    <div className="wow fadeInUp">
                      <p className="benefit-title benefit-title-cmp">{t('customerProductsTitle1')}</p>
                      {t('customerProductsDesc11')}
                    </div>
                  </article>
                </div>
                <div className="col-md-4">
                  <article className="benefit-one">
                    <span className="benefit-icon wow fadeIn">
                      <img src={FeedPosts} />
                    </span>
                    <div className="wow fadeInUp">
                      <p className="benefit-title benefit-title-cmp">{t('customerProductsTitle2')}</p>
                      {t('customerProductsDesc2')}
                    </div>
                  </article>
                </div>
                <div className="col-md-4">
                  <article className="benefit-one">
                    <span className="benefit-icon wow fadeIn">
                      <img src={Notifications} />
                    </span>
                    <div className="wow fadeInUp">
                      <p className="benefit-title benefit-title-cmp">{t('customerProductsTitle3')}</p>
                      {t('customerProductsDesc3')}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitCustomerSection;
