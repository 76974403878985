import React from "react";
import Simple from "../../assets/images/ic-real-estate-simple.svg";
import Exciting from "../../assets/images/ic-real-estate-exciting.svg";
import { useTranslation } from "react-i18next";

const BenefitAboutSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-benefit">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="block-columns">
              <h3>{t("aboutUsSubtitle")}</h3>
              <div className="row">
                <div className="col-md-6">
                  <article className="benefit-one">
                    <span className="benefit-icon wow fadeIn">
                      <img src={Simple} alt="" />
                    </span>
                    <div className="wow fadeInUp">{t("aboutUsDesc1")}</div>
                  </article>
                </div>
                <div className="col-md-6">
                  <article className="benefit-one">
                    <span className="benefit-icon wow fadeIn">
                      <img src={Exciting} alt="" />
                    </span>
                    <div className="wow fadeInUp">{t("aboutUsDesc2")}</div>
                  </article>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitAboutSection;
