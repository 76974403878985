import React, { useState } from "react";
import MainSales from "../../assets/images/sales/pic-sales_main.svg";
import Presence from "../../assets/images/ic-field-presence-where-and-when-you-need-it.svg";
import Active from "../../assets/images/ic-activate-your-digital-super-powers.svg";
import Support from "../../assets/images/ic-full-marketing-support-in-house.svg";
import Fees from "../../assets/images/ic-no-additional-fees.svg";
import Sales2 from "../../assets/images/sales/sales2.png";
import Tools from "../../assets/images/ic-tools.svg";
import Benefit from "../../assets/images/ic-one-more-benefit.svg";
import Label from "../../assets/images/ic-white-label-outsourcing.svg";
import ContactModal from "../../utils/ContactModal";
import IncludeScript from "../../utils/IncludeScript";
import { useTranslation } from "react-i18next";

const OverflowSalesSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <section className="overflow-hidden">
      <ContactModal isOpen={isOpen} setOpen={setIsOpen} isDemo={true} />
      <section className="section-describe section-describe_main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <article className="describe-row">
                <div className="row">
                  <div className="col-lg-4">
                    <article className="describe-text describe-text_top wow fadeInLeft">
                      <p className="title">
                        Evo home{" "}
                        <span className="span2">
                          {t("headerSalesManagementSmall")}
                        </span>
                      </p>
                      <p>
                        {t("salesMainDesc1")}
                        <br />
                        {t("salesMainDesc2")}
                      </p>
                      <a
                        href="javascript:void(0);"
                        onClick={() => setIsOpen(true)}
                        className="btn btn-border"
                      >
                        <span>{t("aboutThisBtn")}</span>
                      </a>
                    </article>
                  </div>
                  <div className="col-lg-8">
                    <div className="describe-right wow fadeInRight">
                      <figure className="describe-figure">
                        <img src={MainSales} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="section-blocks">
        <IncludeScript name="initCarousel3" />
        <IncludeScript name="initWOW" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>{t("salesProductsTitle")}</h3>
              <p className="subtitle">{t("salesProductsDesc")}</p>

              <div className="owl-carousel owl-theme blocks-slider">
                <article className="block">
                  <span className="icon">
                    <img src={Presence} alt="" />
                  </span>
                  <p className="title">{t("salesProductsTitle1")}</p>
                  {t("salesProductsDesc1")}
                </article>
                <article className="block black">
                  <span className="icon">
                    <img src={Active} alt="" />
                  </span>
                  <p className="title">{t("salesProductsTitle2")}</p>
                  {t("salesProductsDesc2")}
                </article>
                <article className="block blue">
                  <span className="icon">
                    <img src={Support} alt="" />
                  </span>
                  <p className="title">{t("salesProductsTitle3")}</p>
                  {t("salesProductsDesc3")}
                </article>
                <article className="block green">
                  <span className="icon">
                    <img src={Fees} alt="" />
                  </span>
                  <p className="title">{t("salesProductsTitle4")}</p>
                  {t("salesProductsDesc4")}
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-describe section-describe_simple">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <article className="describe-row first">
                <div className="row flex-row-reverse">
                  <div className="col-lg-4">
                    <article className="describe-text wow fadeInRight">
                      <p className="title">
                        {t("salesRightTitlep1")}
                        <span className="span1">{t("salesRightTitlep2")}</span>
                        {t("salesRightTitlep3")}
                      </p>
                    </article>
                  </div>
                  <div className="col-lg-8">
                    <div className="describe-left wow fadeInLeft">
                      <figure className="describe-pic">
                        <img src={Sales2} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="section-benefit">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>{t("configuratorProductsTitle")}</h3>
            </div>
            <div className="col-md-4">
              <article className="benefit-one">
                <span className="benefit-icon wow fadeIn">
                  <img src={Label} alt="" />
                </span>
                <div className="wow fadeInUp">
                  <p className="benefit-title">{t("salesAProductsTitle1")}</p>
                  {t("salesAProductsDesc1")}
                </div>
              </article>
            </div>
            <div className="col-md-4">
              <article className="benefit-one">
                <span className="benefit-icon wow fadeIn">
                  <img src={Tools} alt="" />
                </span>
                <div className="wow fadeInUp">
                  <p className="benefit-title">{t("salesAProductsTitle2")}</p>
                  {t("salesAProductsDesc2")}
                </div>
              </article>
            </div>
            <div className="col-md-4">
              <article className="benefit-one">
                <span className="benefit-icon wow fadeIn">
                  <img src={Benefit} alt="" />
                </span>
                <div className="wow fadeInUp">
                  <p className="benefit-title">{t("salesAProductsTitle3")}</p>
                  {t("salesAProductsDesc3")}
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default OverflowSalesSection;
