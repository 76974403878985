import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Base64 } from "js-base64";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.css";
import "./assets/scss/styles.scss";
import "./assets/scss/other.scss";
import './assets/css/aos.css';
import './assets/css/animate.min.css';
import 'react-tabs/style/react-tabs.scss';
import { I18nextProvider } from "react-i18next";
import i18n from "./initi18n";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

const initialState =
  (window as any).DATA !== null && (window as any).DATA !== "{{WINDOW_DATA}}"
    ? Base64.decode((window as any).DATA)
    : "{}";

const parsedInitialState = JSON.parse(initialState || '{}');
const store = configureStore(parsedInitialState);

const name = initialState === "{}" ? "render" : "hydrate";


ReactDOM[name](
  <HelmetProvider>
   <I18nextProvider i18n={i18n}>
     <Provider store={store.store}>
       <PersistGate loading={null} persistor={store.persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
       </PersistGate>
      </Provider>
    </I18nextProvider>
  </HelmetProvider>,
  document.getElementById("root")
);
