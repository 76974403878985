import React from "react";
// import ThankYou from "../assets/images/thank-you.svg";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

interface IThankYouModalProps {
  isOpenThankYouModal: boolean;
  setOpenThankYouModal: any;
}

const ThankYouModal = ({
  isOpenThankYouModal,
  setOpenThankYouModal,
}: IThankYouModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  
  return (
    <div className="modal fade">
      <Modal
        isOpen={isOpenThankYouModal}
        onRequestClose={() => setOpenThankYouModal(false)}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-dialog modal-getoffer">
          <div className="modal-content">
            <div className="modal-body thank-you">
              <div className="thank-you_right">
                <p className="title">{t("modalTitle2")}</p>
                <p className="subtitle">{t("modalDesc2")}</p>
                <div className="text">{t("modalDesc3")}</div>
                <div className="thank-you_btns">
                  <a
                    href="https://calendly.com/evo-home-jonathan"
                    target="_blank"
                    className="btn btn-gradient"
                  >
                    <span>{t("modalBtn1")}</span>
                  </a>
                  <NavLink
                    to={history.location.pathname}
                    className="btn btn-border"
                    data-dismiss="modal"
                    onClick={()=>setOpenThankYouModal(false)}
                  >
                    <span>{t("modalBtn2")}</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ThankYouModal;
