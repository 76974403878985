import React from "react";
import CommonHelmet from "../components/common/CommonHelmet";
import Footer from "../components/MainComponents/Footer";
import Header from "../components/MainComponents/Header";
import NewslatterForm from "../components/MainComponents/NewslatterForm";
import ActionSalesSection from "../components/SalesPage/ActionSalesSection";
import OverflowSalesSection from "../components/SalesPage/OverflowSalesSection";

const SalesPage = () => {
    return(
        <>
      <CommonHelmet
          keywords={
            'gestion des ventes'
          }
          description={
            `Promoteur immobilier, entreprise de construction, société de courtage, Evo home peut vous aider à gérer vos ventes de A à Z.`
          }
        />
        <Header/>
        <OverflowSalesSection/>
        <ActionSalesSection/>
        <NewslatterForm />
        <Footer/>
        </>
    )
}

export default SalesPage