import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useHistory } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import IncludeScript from '../../utils/IncludeScript';

const Footer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const path = history.location.pathname;

  return (
    <footer className="footer wow fadeinUp">
      <IncludeScript name={'initWOW'} />
      <div className="container">
        <section className="row">
          <div className="col-xl-4">
            <Link
              to={path === '/client-manager' ? 'client-manager' : '/'}
              className="footer-logo"
            >
              <img
                src={Logo}
                alt="Evohom (logo)"
              />
            </Link>
            <p className="footer-copyright">Copyright &copy; Evo home. {t('footerCory')}</p>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-sm-7">
                <nav className="footer-nav">
                  <p className="footer-title">Navigation</p>
                  <div className="footer-nav_columns">
                    {path === '/client-manager' ? (
                      <ul className="footer-menu">
                        <li>
                          <a href="/client-manager">{t('clientManagerHome')}</a>
                        </li>
                        {/* <li>
                          <a href="/a-propos-de-evo-home">{t('headerAboutUs')}</a>
                        </li> */}
                        <li>
                          <a href="/privacy">{t('footerPrivacy')}</a>
                        </li>
                      </ul>
                    ) : (
                      <>
                        <ul className="footer-menu">
                          <li>
                            <a href="/crm-immobilier">CRM</a>
                          </li>
                          <li>
                            <a href="/visualiseur-plans-architecture-3D">{t('headerVisualizer')}</a>
                          </li>
                          <li>
                            <NavLink to="/configurateur-immobilier-3D">{t('headerConfigurator')}</NavLink>
                          </li>
                          <li>
                            <a href="/reviewer-architecture">{t('headerReviewer')}</a>
                          </li>
                          <li>
                            <a href="/cmp-plateforme-de-gestion-clients-immobilier">
                              {t('headerCustomerManagmentPlatform')}
                            </a>
                          </li>
                        </ul>
                        <ul className="footer-menu">
                          <li>
                            <a href="/gestion-des-ventes-immobilieres">{t('headerSalesManagement')}</a>
                          </li>
                          <li>
                            <a href="/marketing-digital-expertise-immobiliere">{t('headerDigitalMarketing')}</a>
                          </li>
                          <li>
                            <a href="/a-propos-de-evo-home">{t('headerAboutUs')}</a>
                          </li>
                          <li>
                            <a href="/privacy">{t('footerPrivacy')}</a>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </nav>
              </div>
              <div className="col-sm-5">
                <p className="footer-title">Contact</p>
                <ul className="footer-contact">
                  <li>
                    <span className="caption">Email:</span> <a href="mailto:hello@evo-home.ch">hello@evo-home.ch</a>
                  </li>
                  {/* <li>
                    <span className="caption">Web:</span>{" "}
                    <a href="/">weblink</a>
                  </li>
                  <li>
                    <span className="caption">P:</span> +41 76 461 99 71
                  </li> */}
                </ul>
                <p className="footer-title">{t('footerAddress')}</p>
                <address className="footer-address">
                  Chemin de la petite-boissière 42
                  <br />
                  1208 - Genève
                </address>
              </div>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};
export default Footer;
