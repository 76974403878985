import React from 'react'
import ActionAboutSection from '../components/AboutUsPage/ActionAboutSection'
import BenefitAboutSection from '../components/AboutUsPage/BenefitAboutSection'
import TeamSection from '../components/AboutUsPage/TeamSection'
import CommonHelmet from '../components/common/CommonHelmet'
import Footer from '../components/MainComponents/Footer'
import Header from '../components/MainComponents/Header'
import NewslatterForm from '../components/MainComponents/NewslatterForm'

const AboutUsPage = () => {
    return(
        <>
      <CommonHelmet
          keywords={
            'A propos de EVO HOME'
          }
          description={
            `Toute l'équipe d'EVO Home se tient à disposition de tous les nouveaux challenges liés à l'immobilier. `
          }
        />
        <Header/>
        <TeamSection/>
        <BenefitAboutSection/>
        <ActionAboutSection/>
        <NewslatterForm />
        <Footer/>
        </>
    )
}
export default AboutUsPage
