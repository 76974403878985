import React from "react";
import { useTranslation } from "react-i18next";

const ActionVisualizerSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-action wow fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <article className="action-block">
              <div className="additional-bg">
                <div className="additional-bg_in"></div>
              </div>
              <div className="action-content">
                <h3>{t("configuratorReviewsTitle")}</h3>
                <div className="action-text">
                  <div className="action-text_left">
                    {t("configuratorReviewsDesc")}
                  </div>
                  <div className="action-right">
                    <a href="https://data.evohom.com/evohomeV.prod/visualizer?prop=76" target="_blank" className="btn btn-border">
                      <span>{t("tryDemoBtn")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ActionVisualizerSection;
