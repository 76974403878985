import React, { useState } from "react";
import Marketing from "../../assets/images/marketing/pic-marketing.svg";
import Digital from "../../assets/images/ic-digital-awareness.svg";
import Content from "../../assets/images/ic-content-creation.svg";
import Lead from "../../assets/images/ic-lead-generation.svg";
import Marketing1 from "../../assets/images/marketing/marketing1.png";
import Support from "../../assets/images/ic-project-management-support.svg";
import Reports from "../../assets/images/ic-powerful-analytics-reports.svg";
import Benefit from "../../assets/images/ic-one-more-benefit.svg";
import ContactModal from "../../utils/ContactModal";
import IncludeScript from "../../utils/IncludeScript";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentLang } from "../../redux/localeReducer";

const OverflowMarketingSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const currentLang = useSelector(getCurrentLang);
  return (
    <section className="overflow-hidden">
      <ContactModal isOpen={isOpen} setOpen={setIsOpen} isDemo={true} />
      <section className="section-describe section-describe_main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <article className="describe-row">
                <div className="row">
                  <div className="col-lg-4">
                    <article className="describe-text describe-text_top wow fadeInLeft">
                      {currentLang === "en" ? (
                        <p className="title">
                          {t("marketingProductsTitlep1")}{" "}
                          <span className="span2">
                            {t("marketingProductsTitlep2")}
                          </span>
                        </p>
                      ) : (
                        <p className="title">
                          <span className="span2">
                            {t("marketingProductsTitlep1")}{" "}
                          </span>
                          {t("marketingProductsTitlep2")}
                        </p>
                      )}
                      <p>
                       {t("marketingMainDesc1")}
                       <span className="noWrap">{t("marketingMainDesc2")}</span>
                       {t("marketingMainDesc3")}
                      </p>
                      <a
                        href="javascript:void(0);"
                        onClick={() => setIsOpen(true)}
                        className="btn btn-border"
                      >
                        <span>{t("aboutThisBtn")}</span>
                      </a>
                    </article>
                  </div>
                  <div className="col-lg-8">
                    <div className="describe-right wow fadeInRight">
                      <figure className="describe-figure">
                        <img src={Marketing} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section className="section-blocks">
        <IncludeScript name="initCarousel3" />
        <IncludeScript name="initWOW" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>{t("marketingProductsDesc")}</h3>

              <div className="owl-carousel owl-theme blocks-slider">
                <article className="block">
                  <span className="icon">
                    <img src={Digital} alt="" />
                  </span>
                  <p className="title">
                    {t("marketingProductsTitle1")}
                  </p>
                  {t("marketingProductsDesc1")}
                </article>
                <article className="block light-blue">
                  <span className="icon">
                    <img src={Content} alt="" />
                  </span>
                  <p className="title">{t("marketingProductsTitle2")}</p>
                 {t("marketingProductsDesc2")}
                </article>
                <article className="block blue">
                  <span className="icon">
                    <img src={Lead} alt="" />
                  </span>
                  <p className="title">{t("marketingProductsTitle3")}</p>
                  {t("marketingProductsDesc3")}
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-describe section-describe_simple">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <article className="describe-row first">
                <div className="row flex-row-reverse">
                  <div className="col-lg-4">
                    <article className="describe-text wow fadeInRight">
                      <p className="title">
                        {t("marketingRightTitlep1")} <span className="span2">{t("marketingRightTitlep2")}</span>{t("marketingRightTitlep3")}
                      </p>
                    </article>
                  </div>
                  <div className="col-lg-8">
                    <div className="describe-left wow fadeInLeft">
                      <figure className="describe-pic">
                        <img src={Marketing1} />
                      </figure>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="section-benefit">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>{t("configuratorProductsTitle")}</h3>
            </div>
            <div className="col-md-4">
              <article className="benefit-one">
                <span className="benefit-icon wow fadeIn">
                  <img src={Reports} alt="" />
                </span>
                <div className="wow fadeInUp">
                  <p className="benefit-title">{t("marketingAProductsTitle1")}</p>
                  {t("marketingAProductsDesc1")}
                </div>
              </article>
            </div>
            <div className="col-md-4">
              <article className="benefit-one">
                <span className="benefit-icon wow fadeIn">
                  <img src={Support} />
                </span>
                <div className="wow fadeInUp">
                  <p className="benefit-title">{t("marketingAProductsTitle2")}</p>
                   {t("marketingAProductsDesc2")}
                </div>
              </article>
            </div>
            <div className="col-md-4">
              <article className="benefit-one">
                <span className="benefit-icon wow fadeIn">
                  <img src={Benefit} alt="" />
                </span>
                <div className="wow fadeInUp">
                  <p className="benefit-title">{t("marketingAProductsTitle3")}</p>
                  {t("marketingAProductsDesc3")}
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default OverflowMarketingSection;
