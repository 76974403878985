import React from "react";
import Lm from "../../assets/images/logos/lemanbleu-tv.png";
import Lagefi from "../../assets/images/logos/lagefi.png";
import Tdg from "../../assets/images/logos/tdg.png";
import IncludeScript from "../../utils/IncludeScript";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import i18n from "../../initi18n";

const DescribeSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-describe" id="container7">
      <IncludeScript name={"initWOW"} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="describe-row first">
              <div className="row">
                <div className="col-lg-3">
                  <article className="describe-text wow fadeInLeft">
                    <p className="title">
                      <span className="span1">{t("headerConfigurator")}</span>
                    </p>
                    <p>
                      {t("mainCustomerManagmentPlatformDesc")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-9">
                  <div className="describe-right none wow fadeInRight">
                    <figure className="describe-video">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video4.mp4" : "/Videos/video4fr.mp4"} id={"video4"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row flex-row-reverse">
                <div className="col-lg-3">
                  <article className="describe-text wow fadeInRight">
                    <p className="title">
                      <span className="span2">{t("headerCRM")}</span>
                    </p>
                    <p>
                      {t("mainCRMDesc")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-9">
                  <div className="describe-left none wow fadeInLeft">
                    <figure className="describe-video">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video21.mp4": "/Videos/video21fr.mp4"} id={"video21"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <div className="speech-bubble_block wow fadeInUp">
              <h3>{t("mainIconsTalked")}</h3>
              <div className="speech-bubble_list">
                <div className="speech-bubble turquoise">
                  <img src={Lm} />
                </div>
                <div className="speech-bubble right">
                  <img src={Lagefi} />
                </div>
                <div className="speech-bubble green">
                  <img src={Tdg} />
                </div>
              </div>
            </div>
            <article className="describe-row first">
              <div className="row">
                <div className="col-lg-3">
                  <article className="describe-text wow fadeInLeft">
                    <p className="title">
                      <span className="span1">
                        {t("headerCustomerManagmentPlatform")}
                      </span>
                    </p>
                    <p>
                    {t("mainCustomerManagmentPlatformDesc")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-9">
                  <div className="describe-right none fadeInRight">
                    <figure className="describe-video">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video11.mp4": "/Videos/video11fr.mp4"} id={"video11"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row flex-row-reverse">
                <div className="col-lg-3">
                  <article className="describe-text wow fadeInRight">
                    <p className="title">
                      <span className="span2">{t("headerReviewer")}</span>
                    </p>
                    <p>{t("mainReviewerDesc")}</p>
                  </article>
                </div>
                <div className="col-lg-9">
                  <div className="describe-left none wow fadeInLeft">
                    <figure className="describe-video">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video16.mp4" : "/Videos/video16fr.mp4"} id={"video16"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DescribeSection;
