import React from "react";
import CommonHelmet from "../components/common/CommonHelmet";
import Footer from "../components/MainComponents/Footer";
import Header from "../components/MainComponents/Header";
import NewslatterForm from "../components/MainComponents/NewslatterForm";
import ActionVisualizerSection from "../components/VisualizerPage/ActionVisualizerSection";
import BenefitVisualizerSection from "../components/VisualizerPage/BenefitVisualizerSection";
import BorderVisualizerSection from "../components/VisualizerPage/BorderVisualizerSection";
import DescribeVisualizerSection from "../components/VisualizerPage/DescribeVisualizerSection";
import VisualizerMainSertion from "../components/VisualizerPage/MainVisualizerSection";
const VisualizerPage = () => {
  return (
    <>
       <CommonHelmet
          keywords={
            'plan 3D'
          }
          description={
            `L'outil de visualisation 3D Evo Home permet aux professionnels de l'immobilier de visualiser, partager et présenter leurs projets en 3D.`
          }
        />
      <Header />
      <VisualizerMainSertion />
      <BorderVisualizerSection />
      <DescribeVisualizerSection />
      <BenefitVisualizerSection />
      <ActionVisualizerSection />
      <NewslatterForm />
      <Footer />
    </>
  );
};
export default VisualizerPage;
