import React from "react";
import CommonHelmet from "../components/common/CommonHelmet";
import Footer from "../components/MainComponents/Footer";
import Header from "../components/MainComponents/Header";
import NewslatterForm from "../components/MainComponents/NewslatterForm";
import ActionReviwerSection from "../components/ReviwerPage/ActionReviwerSection";
import BenefitReviwerSection from "../components/ReviwerPage/BenefitReviwerSection";
import BorderReviwerSection from "../components/ReviwerPage/BorderReviwerSection";
import DescribeReviwerSection from "../components/ReviwerPage/DescribeReviwerSection";
import ReviwerMainSection from "../components/ReviwerPage/MainReviwerSection";

const ReviwerPage = () => {
  return (
    <>
    <CommonHelmet
        keywords={
          'reviewer'
        }
        description={
          `Le Reviewer Evo Home est un outil pour communiquer entre les professionnels de l'immobilier et le client final et approuver les choix  architecturaux.`
        }
      />
      <Header />
      <ReviwerMainSection />
      <BorderReviwerSection />
      <DescribeReviwerSection />
      <BenefitReviwerSection />
      <ActionReviwerSection />
      <NewslatterForm />
      <Footer />
    </>
  );
};
export default ReviwerPage;
