import React from "react";
import Discover from "../../assets/images/ic-discover-technical.svg";
import Floorplan from "../../assets/images/ic-add-2-d-floorplan.svg";
import Floor from "../../assets/images/ic-multiple-floor.svg";
import { useTranslation } from "react-i18next";

const BenefitVisualizerSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-benefit">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{t("configuratorProductsTitle")}</h3>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={Discover} alt="" />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">{t("visualizerProductsTitle1")}</p>
                {t("crmProductsDesc1")}
              </div>
            </article>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={Floorplan} alt="" />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">{t("visualizerProductsTitle2")}</p>
                {t("visualizerProductsDesc2")}
              </div>
            </article>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={Floor} alt="" />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">{t("visualizerProductsTitle3")}</p>
                {t("visualizerProductsDesc3")}
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitVisualizerSection;
