import React from "react";
import { useTranslation } from "react-i18next";

const MainPrivacy = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="section-static_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>
                Privacy <span className="span1">Policy</span>
              </h1>
              <article className="section-static">
                <p>{t("privacyDesc1")}</p>
                <p>{t("privacyDesc2")}</p>
              </article>
              <article className="section-static">
                <h3>{t("privacyTitle2")}</h3>
                <p>{t("privacyDesc2p1")}</p>
                <ul className="big">
                  <li>
                    <p className="caption">{t("privacyTitle21")}</p>
                    {t("privacyDesc21")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle22")}</p>
                    {t("privacyDesc22")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle23")}</p>
                    {t("privacyDesc23")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle24")}</p>
                    {t("privacyDesc24")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle25")}</p>
                    {t("privacyDesc25")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle26")}</p>
                    {t("privacyDesc26")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle27")}</p>
                    {t("privacyDesc27")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle28")}</p>
                    {t("privacyDesc28")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle29")}</p>
                    {t("privacyDesc29")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle210")}</p>
                    {t("privacyDesc210")}
                  </li>
                  <li>
                    <p className="caption">{t("privacyTitle211")}</p>
                    {t("privacyDesc211")}
                  </li>
                </ul>
                <div className="subsection">
                  <h4>{t("privacyAdress1Title")}</h4>
                  <address>
                    <span className="font-weight-bold">Evo home</span>
                    <br />
                    42 Chemin de la petite Boissière
                    <br />
                    1208 Genève
                    <br />
                    Switzerland
                  </address>
                  <p>Email: hello@evo-home.ch</p>
                  Website: https://evo-home.ch
                </div>
                <div className="subsection">
                  <h4>{t("privacyAdress2Title")}</h4>
                  <address>
                    <span className="font-weight-bold">Sandro Medina</span>
                    <br />
                    Evo-home Sàrl
                    <br />
                    42 Chemin de la petite Boissière
                    <br />
                    1208 Genève
                    <br />
                    Switzerland
                  </address>
                  <p>Email: hello@evo-home.ch</p>
                  Website: https://evo-home.ch
                </div>
                <p className="font-semibold">{t("privacyAdressSubtitle")}</p>
              </article>
              <article className="section-static">
                <h3>Cookies</h3>
                {t("privacyCookiesDesc")}
              </article>
              <article className="section-static">
                <h3>{t("privacyDataTitle")}</h3>
                {t("privacyDataDesc")}
              </article>
              <article className="section-static">
                <h3>{t("privacyRegistrationTitle")}</h3>
                <p>{t("privacyRegistrationDesc")}</p>
                <p>{t("privacyRegistrationDesc2")}</p>
                {t("privacyRegistrationDesc3")}
              </article>
              <article className="section-static">
                <h3>{t("privacyNewslatterTitle")}</h3>
                {t("privacyNewslatterDesc")}
              </article>
              <article className="section-static">
                <h3>{t("privacyContactTitle")}</h3>
                {t("privacyContactDesc")}
              </article>
              <article className="section-static">
                <h3>{t("privacyPersonalTitle")}</h3>
                <p>{t("privacyPersonalDesc")}</p>
                {t("privacyPersonalSubtitle")}
                <ul>
                  <li>{t("privacyPersonalLi1")}</li>
                  <li>{t("privacyPersonalLi2")}</li>
                  <li>{t("privacyPersonalLi3")}</li>
                  <li>{t("privacyPersonalLi4")}</li>
                  <li>{t("privacyPersonalLi5")}</li>
                  <li>{t("privacyPersonalLi6")}</li>
                  <li>{t("privacyPersonalLi7")}</li>
                  <li>{t("privacyPersonalLi8")}</li>
                  <li>{t("privacyPersonalLi9")}</li>
                </ul>
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle")}</h3>
                {t("privacyProtectionDesc")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle2")}</h3>
                {t("privacyProtectionDesc2")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle3")}</h3>
                {t("privacyProtectionDesc3")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle4")}</h3>
                {t("privacyProtectionDesc4")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle5")}</h3>
                {t("privacyProtectionDesc5")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle6")}</h3>
                {t("privacyProtectionDesc6")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle7")}</h3>
                {t("privacyProtectionDesc7")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle7")}</h3>
                {t("privacyProtectionDesc7")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle8")}</h3>
                {t("privacyProtectionDesc8")}
              </article>
              <article className="section-static">
                <h3>{t("privacyProtectionTitle9")}</h3>
                {t("privacyProtectionDesc9")}
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MainPrivacy;
