import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCurrentLang } from '../../redux/localeReducer';
import useValidation from '../../useHooks/useValidation';
import Input from '../../utils/Input';
import Loader from 'react-loader-spinner';
import service from '../../services/service';
import Procore from '../../assets/images/logos/procore@2x.png';

const ContactMeClientManagerSection = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const currentLang = useSelector(getCurrentLang);
  const { handleSubmit, register, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const validation = useValidation();

  const onSubmit = (data: any) => {
    data.language = currentLang;
    setIsLoading(true);
    service.contactForm(data);
    setIsLoading(false);
    setIsSended(true);
  };

  return (
    <section className="section-contactme wow fadeInUp">
      <div className="container">
        <div className="row contactme-row_main">
          <div className="col-md-8">
            <div className="row">
              <div className="col-lg-12">
                <article className="additional-block contactme-block">
                  <div className="additional-bg contactme-bg">
                    <div className="additional-bg_in"></div>
                  </div>
                  <div className="row contactme-row">
                    <div className="col-12 col-lg-6">
                      <span className="additional-text">{t('customerContactMeTitle')}</span>
                      <br />
                      <figure className="figure contactme-figure">
                        <img src={Procore} />
                      </figure>
                    </div>
                    <div className="col-12 col-lg-6 contactme-col">
                      <span className="additional-text contactme-text">{t('customerContactMeTitle2')}</span>
                      <p className="contactme-p">{t('customerContactMeDesc2')}</p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contactme-action">
              <p className="title">
                {t('customerContactMeTitle3p1')}
                <br />
                {t('customerContactMeTitle3p2')}
              </p>
              {isSended ? (
                <div className="contactme-thankyou">{t('customerContactMeTitleThx')}</div>
              ) : (
                <form
                  className="contactme-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-item">
                    <Input
                      type="email"
                      className="form-control"
                      placeholder="Email*"
                      ref={register(validation.email)}
                      label={''}
                      errors={errors}
                      name={'email'}
                    />
                  </div>
                  {isLoading ? (
                    <Loader
                      type="ThreeDots"
                      color="#00BFFF"
                      height={50}
                      width={100}
                    />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-gradient form-button"
                    >
                      <span>{t('customerContactMeButton')}</span>
                    </button>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactMeClientManagerSection;
