import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import LogoEvo from '../../assets/images/logo.svg';
import Modal from '../../utils/ContactModal';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLang, setLocale } from '../../redux/localeReducer';
import i18n from '../../initi18n';
import LanguageLinks from './LanguageLinks';
import { useTranslation } from 'react-i18next';
import { getIsFirstOpen, setIsFirstOpen } from '../../redux/isFirstOpenReducer';
import WelcomeModal from '../../utils/WelcomeModal';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWelcome, setIsOpenWelcome] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params: any = useParams();
  const history = useHistory();
  const path = history.location.pathname;
  const isServices =
    params.product &&
    (params.product === 'gestion-des-ventes-immobilieres' ||
      params.product === 'marketing-digital-expertise-immobiliere');
  const isNews = path.includes('blog');
  const activeDropdown = 'nav-item nav-item_dropdown active';
  const nonActiveDropdown = 'nav-item nav-item_dropdown';
  const currentLang = useSelector(getCurrentLang);
  const isFirstOpen = useSelector(getIsFirstOpen);

  useEffect(() => {
    if (isFirstOpen) {
      setIsOpenWelcome(false);
      dispatch(setIsFirstOpen());
    }
  }, []);
  const ChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    dispatch(setLocale(lang));
  };
  const isProductActive = () => {
    if (params.product) {
      if (!isServices && !isNews) {
        return activeDropdown;
      }
    }
    return nonActiveDropdown;
  };
  const isServiceActive = () => {
    if (params.product) {
      if (isServices) {
        return activeDropdown;
      }
    }
    return nonActiveDropdown;
  };
  const isNewsActive = () => {
    if (params.product) {
      if (isNews) {
        return activeDropdown;
      }
    }
    return nonActiveDropdown;
  };

  return (
    <header className="navbar-expand-xl fixed-top header header-index">
      <Modal isOpen={isOpen} setOpen={setIsOpen} isDemo={false} />
      <WelcomeModal
        isOpenWelcome={isOpenWelcome}
        setIsOpenWelcome={setIsOpenWelcome}
      />
      <div className="container">
        <div className="header-logo">
          <Link to={path === '/client-manager' ? 'client-manager' : '/'}>
            <img src={LogoEvo} alt="Evohom (logo)" />
          </Link>
        </div>

        <div className="header-main">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#header-menu">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="header-menu">
            <div className="navbar-collapse_scroll">
              <ul className="navbar-nav">
                {path === '/client-manager' ? (
                  <>
                    <li
                      className={
                        path === '/client-manager'
                          ? 'nav-item active'
                          : 'nav-item'
                      }>
                      <NavLink to="/client-manager">
                        {' '}
                        {t('clientManagerHome')}
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink to="/a-propos-de-evo-home"> {t('headerAboutUs')}</NavLink>
                    </li> */}
                  </>
                ) : (
                  <>
                    <li className={isProductActive()}>
                      <div className="dropdown">
                        <NavLink
                          to="/"
                          className="dropdown-toggle"
                          data-toggle="dropdown">
                          {t('headerProducts')}
                        </NavLink>
                        <div className="dropdown-menu">
                          <ul>
                            <li>
                              <NavLink
                                className="dropdown-item"
                                activeClassName="dropdown-item active"
                                to="/crm-immobilier">
                                {t('headerCRM')}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown-item"
                                activeClassName="dropdown-item active"
                                to="/visualiseur-plans-architecture-3D">
                                {t('headerVisualizer')}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown-item"
                                activeClassName="dropdown-item active"
                                to="/configurateur-immobilier-3D">
                                {t('headerConfigurator')}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown-item"
                                activeClassName="dropdown-item active"
                                to="/reviewer-architecture">
                                {t('headerReviewer')}
                                <span className="beta">Beta</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown-item"
                                activeClassName="dropdown-item active"
                                to="/cmp-plateforme-de-gestion-clients-immobilier">
                                {t('headerCustomerManagmentPlatform')}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className={isServiceActive()}>
                      <div className="dropdown">
                        <a
                          href="/"
                          className="dropdown-toggle"
                          data-toggle="dropdown">
                          {t('headerServices')}
                        </a>
                        <div className="dropdown-menu">
                          <ul>
                            <li>
                              <NavLink
                                className="dropdown-item"
                                to="/gestion-des-ventes-immobilieres"
                                activeClassName="dropdown-item active">
                                {t('headerSalesManagement')}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown-item"
                                to="/marketing-digital-expertise-immobiliere"
                                activeClassName="dropdown-item active">
                                {t('headerDigitalMarketing')}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className={isNewsActive()}>
                      <div className="dropdown">
                        <a
                          href="/"
                          className="dropdown-toggle"
                          data-toggle="dropdown">
                          {t('headerNews')}
                        </a>
                        <div className="dropdown-menu">
                          <ul>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://www.evo-home.ch/blog/">
                                {t('headerBlog')}
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://www.evo-home.ch/blog/podcasts/">
                                {t('headerPodkasts')}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    {/* <li className="nav-item">
                    <a href="/">Our Customers</a>
                  </li> */}
                    {/* TODO: change back when will be page */}
                    {path === '/a-propos-de-evo-home' ? (
                      <li className="nav-item active">
                        <NavLink to="/a-propos-de-evo-home">
                          {' '}
                          {t('headerAboutUs')}
                        </NavLink>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <NavLink to="/a-propos-de-evo-home">
                          {' '}
                          {t('headerAboutUs')}
                        </NavLink>
                      </li>
                    )}
                    {/* <li className="nav-item">
                    <a href="/">Contact</a>
                  </li> */}
                  </>
                )}
              </ul>

              <div className="header-right">
                <div className="header-language_mobile">
                  <span>Language</span>
                  <ul>
                    <LanguageLinks
                      currentLang={currentLang}
                      ChangeLanguage={ChangeLanguage}
                      isMobile={true}
                    />
                  </ul>
                </div>

                <div className="dropdown header-language_desktop">
                  <LanguageLinks
                    currentLang={currentLang}
                    ChangeLanguage={ChangeLanguage}
                    isMobile={false}
                  />
                </div>
                <a
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  className="btn btn-contact"
                  data-toggle="modal">
                  <span className="offer-btn">{t('headerOffer')}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
