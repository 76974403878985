import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../initi18n";
import ContactModal from "../../utils/ContactModal";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const MainCRMSection = () => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <main className="section-main" id="container4">
      <ContactModal isOpen={isOpen} setOpen={setIsOpen} isDemo={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-main_content wow fadeInUp">
              <article className="section-main_top">
                <h1>
                  Evo home <span className="span2">CRM</span>
                </h1>
                <p>
                  {t("crmMainDesc1")}
                  <br />
                  {t("crmMainDesc2")}
                </p>
              </article>
              <figure className="section-main_figure">
                <VideoPlayer src={i18n.language == 'en' ? "/Videos/video21.mp4" : "/Videos/video21fr.mp4"} id={"video21"}/>
              </figure>
              <p className="section-main_btn">
                  <a
                    href="javascript:void(0);"
                    className="btn btn-border"
                    onClick={() => setIsOpen(true)}
                  >
                    <span>{t("tryThisBtn")}</span>
                  </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default MainCRMSection;
