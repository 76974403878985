import React from "react";
import BenefitClientManagerSection from "../components/ClientManagerPage/BenefitClientManagerSection";
import BorderClientManagerSection from "../components/ClientManagerPage/BorderClientManagerSection";
import ContactMeClientManagerSection from "../components/ClientManagerPage/ContactMeClientManagerSection";
import DescribeClientManagerSection from "../components/ClientManagerPage/DescribeClientManagerSection";
import MainClientManagerSection from "../components/ClientManagerPage/MainClientManagerSection";
import CommonHelmet from "../components/common/CommonHelmet";
import NewslatterForm from "../components/MainComponents/NewslatterForm";
import Header from "../components/MainComponents/Header";
import Footer from "../components/MainComponents/Footer";

const ClientManagerPage = () => {
  return (
    <>
      <CommonHelmet
          keywords={
            'gestion clients immobilier'
          }
          description={
            `La plateforme de gestions de clients permet un suivi de chantier optimal. Budget, calendrier de visites et travaux, notifications`
          }
        />
      <Header />
      <MainClientManagerSection />
      <BorderClientManagerSection />
      <DescribeClientManagerSection />
      <BenefitClientManagerSection />
      <ContactMeClientManagerSection/>
      <NewslatterForm />
      <Footer />
    </>
  );
};

export default ClientManagerPage;
