import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";

interface IModalProps {
  isOpenWelcome: boolean;
  setIsOpenWelcome: any;
}

const WelcomeModal = (props: IModalProps) => {
  const { t } = useTranslation();
  const { isOpenWelcome, setIsOpenWelcome } = props;
  return (
    <div id="myModal" className="modal fade" role="dialog">
      <Modal
        preventScroll={true}
        isOpen={isOpenWelcome}
        onRequestClose={() => setIsOpenWelcome(false)}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-dialog modal-welcome text-center">
          <div className="modal-content">
            <button
              type="button"
              className="modal-close"
              data-dismiss="modal"
              onClick={() => setIsOpenWelcome(false)}
            >
              &nbsp;
            </button>
            <div className="video-block">
              <VideoPlayer src={"/Videos/wd-evohome.mp4"} id={"wd-evohome"}/>
            </div>
            <div className="modal-body">
              <p className="title">{t("popupTitle")}</p>
              {t("popupDescp1")}
              <br />
              {t("popupDescp2")}
              <a
                href="https://www.evo-home.ch/blog/wild-dots-devient-evo-home/"
                className="btn btn-border"
                onClick={() => setIsOpenWelcome(false)}
              >
                <span>{t("popupBtn")}</span>
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WelcomeModal;
