import React from "react";
import Feedbacks from "../../assets/images/ic-bring-your-feedbacks.svg";
import Feedback from "../../assets/images/ic-get-feedback.svg";
import Approve from "../../assets/images/ic-approve-versions.svg";
import { useTranslation } from "react-i18next";

const BenefitReviwerSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-benefit">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{t("configuratorProductsTitle")}</h3>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={Feedbacks} alt="" />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">
                  {t("reviewerProductsTitle1")}
                </p>
                {t("reviewerProductsDesc1")}
              </div>
            </article>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={Feedback} alt="" />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">{t("reviewerProductsTitle2")}</p>
                {t("reviewerProductsDesc2")}
              </div>
            </article>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={Approve} alt="" />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">{t("reviewerProductsTitle3")}</p>
                {t("reviewerProductsDesc3")}
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitReviwerSection;
