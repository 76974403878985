import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import fr from './assets/i18n/fr';
import en from './assets/i18n/en';


  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      fallbackLng: 'fr',
      resources: {
          fr: {translation: fr},
          en: {translation: en}
      },
      debug: false,
      detection: {
          order: ["localStorage", "cookie"],
          caches: ["localStorage", "cookie"],
      },
      interpolation: {
          escapeValue: false
      }
    });

export default i18n

