import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../initi18n";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const DescribeCRMSection = () => {
  const {t} = useTranslation();
  return (
    <section className="section-describe" id="container3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="describe-row first">
              <div className="row">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInLeft">
                    <p className="title">
                      {t("crmBlocksTitle2p1")} <span className="span2">{t("crmBlocksTitle2p2")}</span>{" "}
                      {t("crmBlocksTitle2p3")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right wow fadeInRight">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video18.mp4" : "/Videos/video18fr.mp4"} id={"video18"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row flex-row-reverse">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInRight">
                  <p className="title">{t("crmDescribeTitlep1")}<span className="span1">{t("crmDescribeTitlep2")}</span></p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-left wow fadeInLeft">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video20.mp4" : "/Videos/video20fr.mp4"} id={"video20"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInLeft">
                    <p className="title">
                      <span className="span2">{t("crmBlocksTitle4p1")}</span> {t("crmBlocksTitle4p2")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right wow fadeInRight">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video19.mp4" : "/Videos/video19fr.mp4"} id={"video19"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DescribeCRMSection;
