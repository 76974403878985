import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "../../initi18n";
import { getCurrentLang } from "../../redux/localeReducer";
import IncludeScript from "../../utils/IncludeScript";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const MainConfiguratorSection = () => {
  const { t } = useTranslation();
  const currentLang = useSelector(getCurrentLang);
  return (
    <main className="section-main section-main_simple" id="container2">
      <IncludeScript name={"initWOW"} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-main_content wow fadeInUp">
              <article className="section-main_text">
              {currentLang === 'en' ?
                <h1>{t("configuratorMainp1")}<span className="span2">{t("configuratorMainp2")}</span><br/>{t("configuratorMainp3")}</h1>
              :
              <h1><span className="span2">{t("configuratorMainp1")}</span>{t("configuratorMainp2")}<br/>{t("configuratorMainp3")}</h1>
              }
                <p>
                  {t("configuratorMainDesc")}
                </p>
              </article>
              <figure className="section-main_figure">
                <VideoPlayer src={i18n.language === 'en' ? "/Videos/video4.mp4" : "/Videos/video4fr.mp4"} id={"video4"}/>
              </figure>
              <p className="section-main_btn">
                  <a href="https://data.evohom.com/evohome.prod/" target="_blank" className="btn btn-border">
                    <span>{t("discoverBtn")}</span>
                  </a>
                </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default MainConfiguratorSection;
