import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../initi18n";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const VisualizerMainSertion = () => {
  const { t } = useTranslation();
  return (
    <main className="section-main" id="container12">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-main_content wow fadeInUp">
              <article className="section-main_top">
                <h1>
                  Evo home <span className="span1">{t("headerVisualizer")}</span>
                </h1>
                <p>{t("visualizerMainDesc")}</p>
              </article>
              <figure className="section-main_figure">
                <VideoPlayer src={i18n.language === 'en' ? "/Videos/video17.mp4" : "/Videos/video17fr.mp4"} id={"video17"}/>
              </figure>
              <p className="section-main_btn">
                  <a
                    href="https://data.evohom.com/evohomeV.prod/visualizer?prop=76"
                    target="_blank"
                    className="btn btn-border"
                  >
                    <span>{t("tryThisBtn")}</span>
                  </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default VisualizerMainSertion;
