import { RootState } from "./store"

const SET_LOCALE = "SET_LOCALE"

export type InitialLocaleType = {
    lang: string
}

const initialLocale: InitialLocaleType = {
    lang: "fr"
}

export const setLocale = (locale: string) => {
   return{
     type: SET_LOCALE,
     locale
   } 
}

type localeAC = {
    type: "SET_LOCALE",
    locale: string
}

export const getCurrentLang = (state: RootState) => state.locale.lang

export const localeReducer = (state = initialLocale, action: localeAC): InitialLocaleType => {
     switch(action.type){
         case SET_LOCALE: 
         return {
             ...state,
             lang : action.locale
         }
         default: return state
     }
}

