import React from "react";
import { useTranslation } from "react-i18next";
type LanguageType = {
  lang: string;
  title: string;
};
type LanguagesType = Array<LanguageType>;

const LanguageLinks = ({ currentLang, ChangeLanguage, isMobile }) => {
  const { t } = useTranslation();

  const languages: LanguagesType = [
    { lang: "en", title: t("langEN") },
    { lang: "fr", title: t("langFR") },
  ];

  const langTitle = languages.find((e: LanguageType) => e.lang === currentLang)?.title;

  if (isMobile) {
    return (
      <>
        {languages.map((language: LanguageType) => (
          <li className={currentLang === language.lang ? "active" : ""} key={language.lang}>
            <a onClick={() => ChangeLanguage(language.lang)}>
              {language.title}
            </a>
          </li>
        ))}
      </>
    );
  } else {
    return (
      <>
        <a className="dropdown-toggle" data-toggle="dropdown">
          {langTitle}
        </a>
        <div className="dropdown-menu">
          {languages.map(
            (language: LanguageType) =>
                <a
                className={`dropdown-item ${language.lang !== currentLang ? '' : 'active'}`}
                onClick={() => ChangeLanguage(language.lang)}
                key={language.lang}
              >
                <p className="lang-link">{language.title}</p>
              </a>
          )}
        </div>
      </>
    );
  }
};
export default LanguageLinks;
