import React from 'react';
import { useTranslation } from 'react-i18next';

const BorderCustomerSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-border section-border-cmp wow fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col">
            <article className="border-block">
              <div className="border-block_in">
                <div className="border-block_content">
                  <div className="row">
                    <div className="col-md-5">
                      <h3>{t('customerBlocksTitle1')}</h3>
                      <h5>
                        {t('customerBlocksDesc11')}
                        <br />
                        {t('customerBlocksDesc12')}
                        <br />
                        {t('customerBlocksDesc13')}
                      </h5>
                    </div>
                    <div className="col-md-7">{t('customerBlocksDesc14')}</div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BorderCustomerSection;
