import React from "react";
import CommonHelmet from "../components/common/CommonHelmet";
import ActionConfiguratorSection from "../components/ConfiguratorPage/ActionConfiguratorSection";
import BenefitConfiguratorSection from "../components/ConfiguratorPage/BenefitConfiguratorSection";
import DescribeConfiguratorSection from "../components/ConfiguratorPage/DescribeConfiguratorSection";
import MainConfiguratorSection from "../components/ConfiguratorPage/MainConfiguratorSection";
import Footer from "../components/MainComponents/Footer";
import Header from "../components/MainComponents/Header";
import NewslatterForm from "../components/MainComponents/NewslatterForm";

const ConfiguratorPage = () => {
  return (
    <>
      <CommonHelmet
          keywords={
            'configurateur 3D'
          }
          description={
            `Le configurateur immobilier proposé par Evo Home aide vos clients à personnaliser et contrôler le processus de développement de leur future propriété.`
          }
        />
      <Header />
      <MainConfiguratorSection />
      <DescribeConfiguratorSection/>
      <BenefitConfiguratorSection/>
      <ActionConfiguratorSection/>
      <NewslatterForm />
      <Footer />
    </>
  );
};
export default ConfiguratorPage;
