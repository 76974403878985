import React from "react";
import { useTranslation } from "react-i18next";

const BorderVisualizerSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-border wow fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col">
            <article className="border-block green">
              <div className="border-block_in">
                <div className="border-block_content">
                  <div className="row">
                    <div className="col-md-5">
                      <h3>{t("configuratorBlocksTitle1")}</h3>
                    </div>
                    <div className="col-md-7">
                      {t("visualizerBlocksDesc1p1")}
                      <br />
                      {t("visualizerBlocksDesc1p2")}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BorderVisualizerSection;
