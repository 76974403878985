import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import storage from 'redux-persist/lib/storage';
import {persistStore, persistReducer} from 'redux-persist';
import { InitialStateType, isFirstOpenReducer } from './isFirstOpenReducer'
import { localeReducer, InitialLocaleType } from './localeReducer'
import { isBrowser } from '../utils/isBrowser';

const persistConfig = {
    key: 'root',
    storage,
    whitelist:['locale', 'isFirstOpen']
}
export type RootState = {
    locale: InitialLocaleType,
    isFirstOpen: InitialStateType
}

const rootReducer = combineReducers({
    locale: localeReducer,
    isFirstOpen: isFirstOpenReducer
} as any)

const reducer: any = isBrowser ? persistReducer(persistConfig, rootReducer) : rootReducer;

export default function configureStore(ssrState: any = {}) {
    const middlewares = [];
  
    const enhancers = [applyMiddleware(...middlewares)];
  
    const someCompose: any = compose;
  
    const store: any = createStore(reducer, ssrState, someCompose(...enhancers));
  
    store.asyncReducers = {}; 
  
    const persistor = persistStore(store);
  
    return {store, persistor};
  }