import React from 'react'
import MainCRMSection from '../components/CRMPage/MainCRMSection'
import Footer from '../components/MainComponents/Footer'
import Header from '../components/MainComponents/Header'
import BorderSection from '../components/CRMPage/BorderSection'
import DescribeCRMSection from '../components/CRMPage/DescribeCRMSection'
import BenefitCRMSection from '../components/CRMPage/BenefitCRMSection'
import ActionCRMSection from '../components/CRMPage/ActionCRMSection'
import NewslatterForm from '../components/MainComponents/NewslatterForm'
import CommonHelmet from '../components/common/CommonHelmet'

const CRMPage = () => {
    return(
        <>
        <CommonHelmet
          keywords={
            'crm immobilier'
          }
          description={
            `EVO Home CRM est un outil dédié aux professionnels de l'immobilier. Améliorez la gestion de vos ventes en collectant, gérant et analysant les données de vos prospects.`
          }
        />
        <Header/>
        <MainCRMSection/>
        <BorderSection/>
        <DescribeCRMSection/>
        <BenefitCRMSection/>
        <ActionCRMSection/>
        <NewslatterForm />
        <Footer/>
        </>
    )
}
export default CRMPage