import { instanceWD } from "./instance";
import {AxiosResponse} from 'axios';

const service = {

    contactForm: (data: any) => {
      const formData = new FormData();
      formData.append('project', 'evohome');
      formData.append('source', 'website');
      formData.append('action', 'contact_request');
      formData.append('message', data.message || '');
      formData.append('contact[first_name]', data.firstName || 'Sir or Madam');
      formData.append('contact[last_name]', data.lastName);
      formData.append('contact[email]', data.email);
      formData.append('contact[phone]', data.phone);
      formData.append('language', data.language);

      const config: any = {
        method: 'post',
        url: 'submitRequest',
        data : formData,
        redirect: 'follow'
      };

      return customFetch(() => instanceWD(config));
    },
    tryDemoForm: (data: any) => {
      const formData = new FormData();
      formData.append('project', 'evohome');
      formData.append('source', 'website');
      formData.append('action', 'demo_request');
      formData.append('message', data.message || '');
      formData.append('contact[first_name]', data.firstName);
      formData.append('contact[last_name]', data.lastName);
      formData.append('contact[email]', data.email);
      formData.append('contact[phone]', data.phone);
      formData.append('language', data.language);

      const config: any = {
        method: 'post',
        url: 'submitRequest',
        data : formData,
        redirect: 'follow'
      };

      return customFetch(() => instanceWD(config));
    },
    
  subscribe: (email: string, language: string) => {
    const formData = new FormData();
    formData.append('project', 'evohome');
    formData.append('source', 'website');
    formData.append('action', 'newsletter_subscription');
    formData.append('contact[email]', email);
    formData.append('language', language);

    const config: any = {
      method: 'post',
      url: 'submitRequest',
      data : formData,
      redirect: 'follow'
    };

    return customFetch(() => instanceWD(config));
  }
};

const customFetch = async <T>(func: () => Promise<AxiosResponse<T>>) => {
  try {
    const res = await func();
    return {
      success: true,
      data: res.data,
      code: res.status,
    };
  } catch (e: any) {
    console.error({e});
    if (e.response && e.response.data && e.response.data.message) {
      throw {
        success: false,
        data: e.response.data.message,
        code: e.response.status,
      };
    }
    throw {
      success: false,
      data: 'error',
      code: 500,
    };
  }
};

export default service;
