import React from "react";
import { useTranslation } from "react-i18next";
import ControlIcon from "../../assets/images/ic-control.svg";
import ManageIcon from "../../assets/images/ic-manage.svg";
import PowerfulIcon from "../../assets/images/ic-powerful.svg";

const BenefitConfiguratorSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-benefit">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{t("configuratorProductsTitle")}</h3>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={PowerfulIcon} alt="" />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">
                  {t("configuratorProductsTitle1")}
                </p>
                {t("configuratorProductsDesc1")}
              </div>
            </article>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={ControlIcon} />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">
                  {t("configuratorProductsTitle2")}
                </p>
                {t("configuratorProductsDesc2")}
              </div>
            </article>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={ManageIcon} alt="" />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">
                  {t("configuratorProductsTitle3")}
                </p>
                {t("configuratorProductsDesc3")}
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BenefitConfiguratorSection;
