import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../initi18n";
import ContactModal from "../../utils/ContactModal";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const ReviwerMainSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <main className="section-main" id="container10">
      <ContactModal isOpen={isOpen} setOpen={setIsOpen} isDemo={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-main_content wow fadeInUp">
              <article className="section-main_top">
                <h1>
                  Evo home <span className="span1">{t("headerReviewer")}</span>{" "}<span className="beta">Beta</span>
                </h1>
                <p>
                  {t("reviewerMainDesc")}
                </p>
              </article>
              <figure className="section-main_figure">
                <VideoPlayer src={i18n.language === 'en' ? "/Videos/video16.mp4": "/Videos/video16fr.mp4"} id={"video16"}/>
              </figure>
              <p className="section-main_btn">
                  <a href="javascript:void(0);" onClick={() => setIsOpen(true)} className="btn btn-border">
                    <span>{t("tryThisBtn")}</span>
                  </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default ReviwerMainSection;
