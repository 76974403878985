import React from 'react';
import {Helmet} from 'react-helmet-async';

interface ICommonHalmetProps {
  description?: string;
  keywords?: string;
  children?: any;
}

const CommonHelmet = React.memo(({children, description, keywords}: ICommonHalmetProps) => {

  return (
    <Helmet>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      {children}
    </Helmet>
  );
});

export default CommonHelmet;
