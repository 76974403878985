import React from "react";
import Footer from "../components/MainComponents/Footer";
import Header from "../components/MainComponents/Header";
import CustomerSection from "../components/MainPage/CustomerSection";
import MainSection from "../components/MainPage/MainSection";
import NewslatterSection from "../components/MainComponents/NewslatterSection";
import { useParams } from "react-router-dom";
import CRMPage from "./CRMPage";
import VisualizerPage from "./VisualizerPage";
import ConfiguratorPage from "./ConfiguratorPage";
import ReviwerPage from "./ReviwerPage";
import SalesPage from "./SalesPage";
import MarketingPage from "./MarketingPage";
import CustomerPlatformPage from "./CustomerPlatformPage";
import DescribeSection from "../components/MainPage/DescribeSection";
import ReviewsSection from "../components/MainPage/ReviewsSection";
import NewslatterForm from "../components/MainComponents/NewslatterForm";

const MainPage = () => {
  const params: any = useParams();
  const product = params.product;

  switch(product){
    case 'crm-immobilier': {
      return <CRMPage/>
    }
    case 'visualiseur-plans-architecture-3D':{
      return <VisualizerPage/>
    }
    case 'configurateur-immobilier-3D':{
      return <ConfiguratorPage/>
    }
    case 'reviewer-architecture':{
      return <ReviwerPage/>
    }
    case 'gestion-des-ventes-immobilieres':{
      return <SalesPage/>
    }
    case 'marketing-digital-expertise-immobiliere':{
      return <MarketingPage/>
    }
    case 'cmp-plateforme-de-gestion-clients-immobilier':{
      return <CustomerPlatformPage/>
    }
    default: {
      return (
        <>
          <Header />
          <MainSection />
          <CustomerSection />
          <DescribeSection />
          <ReviewsSection />
          <NewslatterSection />
          <NewslatterForm />
          <Footer />
        </>
      );
    }
  }
};
export default MainPage;
