import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../initi18n";
import IncludeScript from "../../utils/IncludeScript";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
const DescribeConfiguratorSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-describe" id="container1">
      <IncludeScript name={"initWOW"} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="describe-row first">
              <div className="row">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInLeft">
                  <p className="title"><span className="span1">{t("configuratorDescribe1p1")}</span>{t("configuratorDescribe1p2")}</p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right bottom right wow fadeInRight">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video1.mp4": "/Videos/video1fr.mp4"} id={"video1"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row flex-row-reverse">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInRight">
                  <p className="title"><span className="span2">{t("configuratorDescribe2p1")}</span>{t("configuratorDescribe2p2")}</p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-left bottom wow fadeInLeft">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video2.mp4": "/Videos/video2fr.mp4"} id={"video2"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInLeft">
                  <p className="title"><span className="span2">{t("configuratorDescribe3p1")}</span>{t("configuratorDescribe3p2")}</p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right bottom right wow fadeInRight">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video3.mp4" : "/Videos/video3fr.mp4"} id={"video3"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DescribeConfiguratorSection;
