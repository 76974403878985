import React from "react";
import { useTranslation } from "react-i18next";
import GirlPic from "../../assets/images/pic-girl.svg";
import IncludeScript from "../../utils/IncludeScript";

const NewslatterSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-additional wow fadeInUp">
      <IncludeScript name={"initWOW"} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <article className="additional-block">
              <div className="additional-bg">
                <div className="additional-bg_in"></div>
              </div>
              <div className="additional-content">
                <span className="additional-text">{t("tryTodayBtn")}</span>
                <div className="additional-right">
                  <a href="https://calendly.com/evo-home-jonathan" className="btn btn-border" target="_blank">
                    <span>{t("bookBtn")}</span>
                  </a>
                  <figure className="additional-pic">
                    <img src={GirlPic} width="221" height="230" />
                  </figure>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default NewslatterSection;
