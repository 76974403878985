import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../initi18n';
import Procore from '../../assets/images/logos/procore.png';
import Docusign from '../../assets/images/logos/docusign.png';
import ContactModal from '../../utils/ContactModal';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const MainCustomerPlatformSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <main
      className="section-main section-main-cmp"
      id="container4"
    >
      <ContactModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        isDemo={true}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-main_content section-main-cmp-content wow fadeInUp">
              <article className="section-main_top section-main-cmp-top">
                <h1 className="span3">{t('customerMainTitle')}</h1>
                <p>
                  {t('customerMainDesc1')}
                  <br />
                  {t('customerMainDesc2')}
                </p>
                <div className="section-main-cmp-top-logos">
                  <ul className="section-main-cmp-top-logos-list">
                    <li>
                      <figure className="section-main-cmp-top-logos_procore">
                        <img src={Procore} />
                      </figure>
                    </li>
                    <li>
                      <figure className="section-main-cmp-top-logos_docusign">
                        <img src={Docusign} />
                      </figure>
                    </li>
                  </ul>
                </div>
              </article>
              <figure className="section-main_figure">
                <VideoPlayer
                  src={i18n.language == 'en' ? '/Videos/video11.mp4' : '/Videos/video11fr.mp4'}
                  id={'video11'}
                />
              </figure>
              <p className="section-main_btn section-main-cmp-top-button">
                <a
                  href="javascript:void(0);"
                  className="btn btn-gradient"
                  onClick={() => setIsOpen(true)}
                >
                  <span>{t('getStartedCustomerBtn')}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default MainCustomerPlatformSection;
