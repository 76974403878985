import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { isBrowser } from '../../utils/isBrowser';
import defaultVideoPlayer from './defaultVideoPlayer';

interface IVideoPlayerProps {
  src: string;
  id: string;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  playsinline?: boolean;
  playing?: boolean;
}

type DefaultVideoPlayerType = typeof defaultVideoPlayer;

const getDefaultVideoPlayerProps = ({ loop, muted, controls, playsinline }: Partial<DefaultVideoPlayerType>) => {
  return {
    loop: loop === undefined ? defaultVideoPlayer.loop : loop,
    muted: muted === undefined ? defaultVideoPlayer.muted : muted,
    controls: controls === undefined ? defaultVideoPlayer.controls : controls,
    playsinline: playsinline === undefined ? defaultVideoPlayer.playsinline : playsinline,
  };
};

const VideoPlayer = ({ src, id, loop, muted, controls, playsinline, playing }: IVideoPlayerProps) => {
  const ref = useRef() as any;
  const [isPlay, setIsPlay] = useState(false);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', isVisible);
      isVisible();
    }
  }, [isBrowser]);

  const isVisible = useCallback(() => {
    if (playing === undefined) {
      let element = document.getElementById(id);
      if (element) {
        let position = element.getBoundingClientRect();

        if (position.top < window.innerHeight && position.bottom >= 0) {
          setIsPlay(true);
        } else {
          setIsPlay(false);
        }
      }
    }
  }, []);

  if (!isBrowser) {
    return null;
  }
  return (
    <ReactPlayer
      ref={ref}
      width="100%"
      height="auto"
      playing={playing || isPlay}
      id={id}
      url={src}
      onReady={isVisible}
      {...getDefaultVideoPlayerProps({ loop, muted, controls, playsinline })}
    />
  );
};

export default VideoPlayer;
