import React from "react";
import TaskMng from '../../assets/images/ic-task-management.svg'
import Powerful from '../../assets/images/ic-powerful-analytics.svg'
import Tag from '../../assets/images/ic-tag-and-organize.svg'
import { useTranslation } from "react-i18next";

const BenefitCRMSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-benefit">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{t("crmProductsTitle")}</h3>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={Tag} />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">{t("crmProductsTitle1")}</p>
                {t("crmProductsDesc1")}
              </div>
            </article>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={TaskMng} />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">{t("crmProductsTitle2")}</p>{t("crmProductsDesc2")}
              </div>
            </article>
          </div>
          <div className="col-md-4">
            <article className="benefit-one">
              <span className="benefit-icon wow fadeIn">
                <img src={Powerful} />
              </span>
              <div className="wow fadeInUp">
                <p className="benefit-title">
                  {t("crmProductsTitle3")}
                </p>
                {t("crmProductsDesc3")}
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BenefitCRMSection;
