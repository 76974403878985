import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../initi18n";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const DescribeVisualizerSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-describe" id="container11">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="describe-row first">
              <div className="row">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInLeft">
                    <p className="title">
                      {t("visualizerBlocksTitle2p1")}
                      <span className="span1">
                        {t("visualizerBlocksTitle2p2")}
                      </span>{" "}
                      {t("visualizerBlocksTitle2p3")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right wow fadeInRight">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video5.mp4" : "/Videos/video5fr.mp4"} id={"video5"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row flex-row-reverse">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInRight">
                    <p className="title">
                      <span className="span2">{t("visualizerBlocksTitle3p1")}</span>{t("visualizerBlocksTitle3p2")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-left wow fadeInLeft">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video6.mp4" : "/Videos/video6fr.mp4"} id={"video6"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
            <article className="describe-row">
              <div className="row">
                <div className="col-lg-4">
                  <article className="describe-text wow fadeInLeft">
                    <p className="title">
                      {t("visualizerBlocksTitle4p1")}{" "}
                      <span className="span1">
                        {t("visualizerBlocksTitle4p2")}
                      </span>{" "}
                      {t("visualizerBlocksTitle4p3")}
                    </p>
                  </article>
                </div>
                <div className="col-lg-8">
                  <div className="describe-right wow fadeInRight">
                    <figure className="describe-pic">
                      <VideoPlayer src={i18n.language === 'en' ? "/Videos/video7.mp4" : "/Videos/video7fr.mp4"} id={"video7"}/>
                    </figure>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DescribeVisualizerSection;
