import React from "react";
import CommonHelmet from "../components/common/CommonHelmet";
import Footer from "../components/MainComponents/Footer";
import Header from "../components/MainComponents/Header";
import NewslatterForm from "../components/MainComponents/NewslatterForm";
import ActionMarketingSection from "../components/MarketingPage/ActionMarketingSection";
import OverflowMarketingSection from "../components/MarketingPage/OverflowMarketingSection";

const MarketingPage = () => {
  return (
    <>
      <CommonHelmet
          keywords={
            'marketing digital'
          }
          description={
            `Création de brochure, site web, génération de leads qualifiés, l'expertise immobilière en marketing digital est un atout sûr pour générer des prospects. `
          }
        />
      <Header />
      <OverflowMarketingSection />
      <ActionMarketingSection />
      <NewslatterForm />
      <Footer />
    </>
  );
};

export default MarketingPage;
