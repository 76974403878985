import React from "react";
import IncludeScript from "../../utils/IncludeScript";
import MailIcon from "../../assets/images/icon-male.svg";
import { useTranslation } from "react-i18next";

const ReviewsSection = () => {
  const {t} = useTranslation();
  return (
    <section className="section-reviews">
      <IncludeScript name={"initCarousel"} />
      <IncludeScript name={"initWOW"} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h3>{t("mainReviewsTitle")}</h3>
            <div className="section-reviews_content">
              <div
                className="section-reviews_bg wow fadeInUp"
                data-wow-delay="1s"
              ></div>
              <div className="section-reviews_bg2 wow fadeInUp"></div>
              <div
                className="owl-carousel owl-theme reviews-slider wow fadeIn"
                data-wow-delay="2s"
              >
                <div className="item">
                  <article className="reviews-one">
                    <aside className="reviews-one_left">
                      <figure className="reviews-one_pic">
                        <img src={MailIcon} alt="" />
                      </figure>
                      <span className="quote"></span>
                    </aside>
                    <div className="reviews-one_right">
                      <div className="reviews-one_author">
                      <p className="name">Thang Nguyen</p>
                      COO Swissroc Group
                      </div>
                      <div className="reviews-one_text">
                       {t("reviews1p1")}
                        <br />
                        {t("reviews1p2")}
                      </div>
                    </div>
                  </article>
                </div>
                <div className="item">
                  <article className="reviews-one">
                    <aside className="reviews-one_left">
                      <figure className="reviews-one_pic">
                        <img src={MailIcon} alt="" />
                      </figure>
                      <span className="quote"></span>
                    </aside>
                    <div className="reviews-one_right">
                      <div className="reviews-one_author">
                        <p className="name">Miguel Bermudez</p>
                        {t("reviews2desc")}
                      </div>
                      <div className="reviews-one_text">
                       {t("reviews2")}
                      </div>
                    </div>
                  </article>
                </div>
                <div className="item">
                  <article className="reviews-one">
                    <aside className="reviews-one_left">
                      <figure className="reviews-one_pic">
                        <img src={MailIcon} alt="" />
                      </figure>
                      <span className="quote"></span>
                    </aside>
                    <div className="reviews-one_right">
                      <div className="reviews-one_author">
                        <p className="name">{t("reviews3autor")}</p>
                      </div>
                      <div className="reviews-one_text">
                       {t("reviews3")}
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ReviewsSection;
