import React from "react";
import Tb from "../../assets/images/about/tb.png";
import Tn from "../../assets/images/about/tn.jpg";
import Ms from "../../assets/images/about/ms.jpg";
import Vf from "../../assets/images/about/vf.png";
import Sm from "../../assets/images/about/sm.png";
import Mm from "../../assets/images/about/mm.png";
import Ag from "../../assets/images/about/ag.png";
import Hl from "../../assets/images/about/hl.jpg";
import Mj from "../../assets/images/about/mj.png";
import Rs from "../../assets/images/about/rs.png";
import Vp from "../../assets/images/about/vp.png";
import Sc from "../../assets/images/about/sc.png";
import LinkedIcon from "../../assets/images/icon-linkedin.svg";
import IncludeScript from "../../utils/IncludeScript";
import { useTranslation } from "react-i18next";

const TeamSection = () => {
  const { t } = useTranslation();
  return (
    <section className="section-team">
      <IncludeScript name={"initCarousel2"} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>
              {t("aboutusTitlep1")}<span>{t("aboutusTitlep2")}</span>
            </h2>
            <p className="subtitle">{t("aboutusTeam")}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Sm} alt="" />
                <a
                  href="https://www.linkedin.com/in/medinasandro/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Sandro Medina</p>
                Co-founder - Operations
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Tb} alt="" />
                <a
                  href="https://www.linkedin.com/in/tafsirba/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Tafsir BA</p>
                Co-founder - Product & Solutions
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Vf} alt="" />
                <a
                  href="https://www.linkedin.com/in/vanessa-francis/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Vanessa Francis</p>
                Co-founder - Project & Customer management
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Mm} alt="" />
                <a
                  href="https://www.linkedin.com/in/martina-mounier-6260a857/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Martina Mounier</p>
                Program Manager
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Rs} alt="" />
                <a
                  href="https://www.linkedin.com/in/ryta-s-b309a137/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Ryta Shygyda</p>
                Program Manager
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Vp} />
                <a
                  href="https://www.linkedin.com/in/valerii-filipets-965b7722/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Valerii Filipets</p>
                Senior Developer
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Ag} alt="" />
                <a
                  href="https://www.linkedin.com/in/amandine-beausse-16052263/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Amandine Beausse</p>
                Marketing Manager
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Mj} alt="" />
                <a
                  href="https://www.linkedin.com/in/marie-janot-3b607a11a/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Marie Janot</p>
                Senior Project Manager
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Hl} alt="" />
                <a
                  href="https://www.linkedin.com/in/hind-laanouni-721a0b169/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Hind Laanouni</p>
                Junior Project Manager
              </div>
            </div>
          </div>
        </div>
        <div className="row team-board">
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Sc} />
                <a href="#" target="_blank" className="link-out">
                  <img src={LinkedIcon} />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Santiago Cantaluppi</p>
                3D Modeling Manager
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Tn} alt="" />
                <a
                  href="https://www.linkedin.com/in/thang-nguyen-swiss/"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Thang Nguyen</p>
                Board Member
              </div>
              <span className="team-rank">Board members</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 team-cell">
            <div className="team-one">
              <figure className="team-pic">
                <img src={Ms} alt="" />
                <a
                  href="https://www.linkedin.com/in/mykola-slobodian"
                  target="_blank"
                  className="link-out"
                >
                  <img src={LinkedIcon} alt="" />
                </a>
              </figure>
              <div className="team-info">
                <p className="team-name">Mykola Slobodian</p>
                Honorary Board Member
              </div>
              <span className="team-rank">Board members</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
