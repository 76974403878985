import React from "react";
import Footer from "../components/MainComponents/Footer";
import Header from "../components/MainComponents/Header";
import MainPrivacy from "../components/PrivacyPage/MainPrivacy";


const PrivacyPage = () => {
    return(
        <>
        <Header/>
        <MainPrivacy/>
        <Footer/>
        </>
    )
} 
export default PrivacyPage